import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";

import clienteAxios from "../../../../config/axios";
import {
  validarDocumento,
  validarEmail,
} from "../../../../config/validaciones";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  FormLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  LinearProgress,
} from "@mui/material";
import { AddCircle, Cancel, Delete, Search } from "@mui/icons-material";
import { useModalStyle } from "../../../styles/styleModal";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  const clases = useModalStyle();
  const [clienteProveedor, setclienteProveedor] = useState("CLIENTES");
  const [clientesData, setclientesData] = useState([]);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);

  const [cargando, setcargando] = useState(false);
  const [paises, setpaises] = useState(null);
  const [setprovincias] = useState([]);
  const [setcantones] = useState([]);
  useEffect(() => {
    setclienteProveedor(estadoRequest);
  }, [estadoRequest]);

  useEffect(() => {
    if (!paises) {
      try {
        const getData = async () => {
          const res = await clienteAxios.get(`/paises/0`);

          setpaises(res.data.data);
        };
        getData();
      } catch (error) {}
    }
    if (editar && paises) {
      const getData = async () => {
        const pais = paises.find(
          (item) => item.paisNombre === ObjectoActivo.perPais
        );
        const provinciasRes = await clienteAxios.get(
          `/provincias/filtro/${pais.paisId}`
        );

        setprovincias(provinciasRes.data.data);
        const provincia = provinciasRes.data.data.find(
          (provincia) => provincia.provNombre === ObjectoActivo.perProvincia
        );

        const cantonesRes = await clienteAxios.get(
          `/cantones/filtro/${provincia.provId}`
        );

        setcantones(cantonesRes.data.data);
      };
      getData();
    }
    // eslint-disable-next-line
  }, [editar]);
  // eslint-disable-next-line
  const {
    // cliNombreComercial,
    cliDocTipo,
    cliDocNumero,
    // perPerfil,
    cliRazonSocial,
    cliDireccion,
    perObligado,
    perCredito,
    cliTelefono,
    cliCelular,
    perEstado,
    cliObservacion,
    perEmail,
    // perPais,
    // perProvincia,
    // perCanton,
    perTipoProveedor,
    perTiempoCredito,
    perOtros,
    documentos,
    // perNickname,
    // errorAutorizacion,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    cliDocTipo: false,
    cliDocNumero: false,
    cliRazonSocial: false,
    cliDireccion: false,
    cliTelefono: false,
    perPais: false,
    perProvincia: false,
    perCanton: false,
    perTipoProveedor: false,
    perObligado: false,
    perTiempoCredito: false,
    perCredito: false,
    perEmail: false,
  });

  useEffect(() => {
    if (editar) {
      setitem({
        ...ObjectoActivo,
      });
    } else {
      setitem({
        ...ObjectoActivo,
        cliDocTipo: clienteProveedor === "USUARIOS" ? "PASAPORTE" : "RUC",
        perOtros: ObjectoActivo.perOtros ? ObjectoActivo.perOtros : [],
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const [docSearch, setdocSearch] = useState("");
  const [cargandoDoc, setcargandoDoc] = useState(false);
  const defaultActive = () => {
    setdocSearch("");
    changeObjetoActivoState({
      // CLIENTE
      //   cliId: 1,
      perOtros: ["ZZZ9999"],
      perNickname: "",
      perObligado: "NO",
      perTipoProveedor: "BIENES",
      perTiempoCredito: "0",
      perCredito: "NO",

      perGuardias: "1",
      documentos: {},
      cliTiempoInicioContrato: "",
      cliTiempoFinalContrato: "",
      perPerfil: [],
      cliDocTipo: "RUC",
      cliDocNumero: "",
      cliRazonSocial: "",
      cliNombreComercial: "",
      cliNombres: "",
      cliDireccion: "",
      cliInstitucion: "PUBLICA",
      cliUbicacion: {
        longitud: -79.178298,
        latitud: -0.254916,
      },
      cliTelefono: [],
      cliCelular: "",
      perEmail: [],
      cliAdministradorContrato: "",
      cliCiudad: "",
      cliArmasAsignadas: [],
      cliDatosOperativos: [],
      cliEquiposAsignados: [],
      cliOtrosEquipos: "",
      cliEmpresa: [],
      perPais: "ECUADOR",
      perProvincia: "SANTO DOMINGO DE LOS TSÁCHILAS",
      perCanton: "SANTO DOMINGO",
      perEstado: "ACTIVO",
      perFoto: "",
      perLogo: "",
      cliUser: "",
      cliObservacion: "",
      cliFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      cliDocTipo: false,
      cliDocNumero: false,
      cliRazonSocial: false,
      cliDireccion: false,
      cliTelefono: false,
      perPais: false,
      perProvincia: false,
      perCanton: false,
      perTipoProveedor: false,
      perObligado: false,
      perTiempoCredito: false,
      perCredito: false,
      perEmail: false,
    });
    setseccionActive({
      datosPersonalesSeccion: true,
      datosContablesSeccion: false,
    });
    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    datosPersonalesSeccion: true,
    datosContablesSeccion: false,
  });
  const { datosPersonalesSeccion, datosContablesSeccion } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    try {
      await validarDocumento(cliDocNumero);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        cliDocNumero: true,
      });
    }

    if (clienteProveedor === "CLIENTES") {
      if (
        item.cliDocTipo.trim() === "" ||
        item.cliDocNumero.trim() === "" ||
        item.cliRazonSocial.trim() === "" ||
        item.cliDireccion.trim() === "" ||
        item.cliTelefono === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        item.perOtros.length === 0
      ) {
        mostrarAlerta("Faltan campos obligatorios", "error");
        return seterror({
          cliDocTipo: item.cliDocTipo.trim() === "" ? true : false,
          cliDocNumero: item.cliDocNumero.trim() === "" ? true : false,
          cliRazonSocial: item.cliRazonSocial.trim() === "" ? true : false,
          cliDireccion: item.cliDireccion.trim() === "" ? true : false,
          cliTelefono: item.cliTelefono === "" ? true : false,
          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
          perOtros: item.perOtros.length === 0 ? true : false,
        });
      }
    } else {
      if (
        item.cliDocTipo.trim() === "" ||
        item.cliDocNumero.trim() === "" ||
        item.cliRazonSocial.trim() === "" ||
        item.cliDireccion.trim() === "" ||
        item.cliTelefono === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perTipoProveedor.trim() === "" ||
        item.perObligado.trim() === "" ||
        item.perTiempoCredito.trim() === "" ||
        item.perCredito.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        })
      ) {
        mostrarAlerta("Faltan campos obligatorios", "error");
        return seterror({
          cliDocTipo: item.cliDocTipo.trim() === "" ? true : false,
          cliDocNumero: item.cliDocNumero.trim() === "" ? true : false,
          cliRazonSocial: item.cliRazonSocial.trim() === "" ? true : false,
          cliDireccion: item.cliDireccion.trim() === "" ? true : false,
          cliTelefono: item.cliTelefono === "" ? true : false,
          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perTipoProveedor: item.perTipoProveedor.trim() === "" ? true : false,
          perObligado: item.perObligado.trim() === "" ? true : false,
          perTiempoCredito: item.perTiempoCredito.trim() === "" ? true : false,
          perCredito: item.perCredito.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
        });
      }
    }

    try {
      setcargando(true);
      console.log({ item });
      if (item.cliId) {
        socket.current.emit("client:actualizarData", {
          ...item,
          nuevo: true,
          existeEnLaTabla: item.perPerfil.some((item) => {
            if (clienteProveedor === "CLIENTES") {
              return item === "CLIENTE";
            } else {
              return item === "PROVEEDOR";
            }
          }),
          tabla: "proveedor",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          cliUser: usuario.usuario,
          cliEmpresa: [
            ...new Set([...(item.cliEmpresa || []), usuario.rucempresa]),
          ],
          perPerfil: [...new Set([...item.perPerfil, "PROVEEDOR"])],
        });
      } else {
        console.log(
          trimPropsItem({
            ...item,
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            cliUser: usuario.usuario,
            cliEmpresa: [
              ...new Set([...(item.cliEmpresa || []), usuario.rucempresa]),
            ],
            perPerfil: [...new Set([...item.perPerfil, "PROVEEDOR"])],
          })
        );
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...item,
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            cliUser: usuario.usuario,
            cliEmpresa: [
              ...new Set([...(item.cliEmpresa || []), usuario.rucempresa]),
            ],
            perPerfil: [...new Set([...item.perPerfil, "PROVEEDOR"])],
          })
        );
      }
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      await validarDocumento(cliDocNumero);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        cliDocNumero: true,
      });
    }
    if (clienteProveedor === "CLIENTES") {
      if (
        item.cliDocTipo.trim() === "" ||
        item.cliDocNumero.trim() === "" ||
        item.cliRazonSocial.trim() === "" ||
        item.cliDireccion.trim() === "" ||
        item.cliTelefono === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        item.perOtros.length === 0
      ) {
        mostrarAlerta("Faltan campos obligatorios", "error");
        return seterror({
          cliDocTipo: item.cliDocTipo.trim() === "" ? true : false,
          cliDocNumero: item.cliDocNumero.trim() === "" ? true : false,
          cliRazonSocial: item.cliRazonSocial.trim() === "" ? true : false,
          cliDireccion: item.cliDireccion.trim() === "" ? true : false,
          cliTelefono: item.cliTelefono === "" ? true : false,
          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
          perOtros: item.perOtros.length === 0 ? true : false,
        });
      }
    } else {
      if (
        item.cliDocTipo.trim() === "" ||
        item.cliDocNumero.trim() === "" ||
        item.cliRazonSocial.trim() === "" ||
        item.cliDireccion.trim() === "" ||
        item.cliTelefono === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perTipoProveedor.trim() === "" ||
        item.perObligado.trim() === "" ||
        item.perTiempoCredito.trim() === "" ||
        item.perCredito.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        })
      ) {
        mostrarAlerta("Faltan campos obligatorios", "error");
        return seterror({
          cliDocTipo: item.cliDocTipo.trim() === "" ? true : false,
          cliDocNumero: item.cliDocNumero.trim() === "" ? true : false,
          cliRazonSocial: item.cliRazonSocial.trim() === "" ? true : false,
          cliDireccion: item.cliDireccion.trim() === "" ? true : false,
          cliTelefono: item.cliTelefono === "" ? true : false,
          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perTipoProveedor: item.perTipoProveedor.trim() === "" ? true : false,
          perObligado: item.perObligado.trim() === "" ? true : false,
          perTiempoCredito: item.perTiempoCredito.trim() === "" ? true : false,
          perCredito: item.perCredito.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
        });
      }
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          cliUser: usuario.usuario,
          cliEmpresa: [
            ...new Set([...(item.cliEmpresa || []), usuario.rucempresa]),
          ],
          tabla: "proveedor",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}{" "}
                  {clienteProveedor === "CLIENTES"
                    ? "Cliente"
                    : clienteProveedor === "PROVEEDORES"
                    ? "Proveedor"
                    : "Usuario"}
                  .
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={datosPersonalesSeccion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() =>
                      setseccionActive({ datosPersonalesSeccion: true })
                    }
                  >
                    Datos Personales
                  </Button>
                  <Button
                    variant={datosContablesSeccion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() =>
                      setseccionActive({ datosContablesSeccion: true })
                    }
                  >
                    Otros datos
                  </Button>
                </Box>

                <FormControlLabel
                  control={
                    <Switch
                      checked={perEstado === "ACTIVO" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                      color="primary"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box className={clases.container}>
                {datosPersonalesSeccion ? (
                  <>
                    <Box
                      className={clases.formAllW}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <TextField
                        disabled={editar}
                        fullWidth
                        size="small"
                        required
                        label={"Número Doc."}
                        error={error.cliDocNumero}
                        value={docSearch}
                        onChange={async (e) => {
                          setdocSearch(e.target.value);
                          setitem({ ...item, cliDocNumero: e.target.value });
                        }}
                        helperText={`Presione "Enter" para buscar`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                aria-label=""
                                onClick={async (e) => {
                                  if (docSearch === "") {
                                    return mostrarAlerta(
                                      "Ingrese un documento",
                                      "error"
                                    );
                                  }
                                  try {
                                    setcargandoDoc(true);
                                    const res = await clienteAxios.get(
                                      `personas/searchByCedulaOfRuc/0?search=${docSearch}&perfil=${"PROVEEDOR"}`
                                    );
                                    if (res?.data) {
                                      changeObjetoActivoState({
                                        ...res.data,
                                        documentos: {
                                          ...documentos,
                                          ...res.data.documentos,
                                        },
                                      });
                                      setitem({
                                        ...res.data,
                                        documentos: {
                                          ...documentos,
                                          ...res.data.documentos,
                                        },
                                      });
                                    }
                                    setcargandoDoc(false);
                                  } catch (error) {
                                    setcargandoDoc(false);
                                    console.log({ error });
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                    setitem({
                                      ...ObjectoActivo,
                                      perDocNumero: docSearch,
                                    });
                                  }
                                }}
                              >
                                <Search color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={async (e) => {
                          if (e.key !== "Enter") {
                            return;
                          }
                          console.log(e.target.value);
                          if (e.target.value === "") {
                            return mostrarAlerta(
                              "Ingrese un documento",
                              "error"
                            );
                          }
                          try {
                            setcargandoDoc(true);
                            setitem({
                              ...item,
                              perDocNumero: e.target.value,
                            });
                            const res = await clienteAxios.get(
                              `personas/searchByCedulaOfRuc/0?search=${
                                e.target.value
                              }&perfil=${"PROVEEDOR"}`
                            );
                            if (res?.data) {
                              changeObjetoActivoState({
                                ...res.data,
                                documentos: {
                                  ...documentos,
                                  ...res.data.documentos,
                                },
                              });
                              setitem({
                                ...res.data,
                                documentos: {
                                  ...documentos,
                                  ...res.data.documentos,
                                },
                              });
                            }
                            setcargandoDoc(false);
                          } catch (error) {
                            console.log({ error });
                            setcargandoDoc(false);
                            mostrarAlerta(error?.response?.data?.msg, "error");
                            setitem({
                              ...ObjectoActivo,
                              perDocNumero: docSearch,
                            });
                          }
                        }}
                      />
                      {cargandoDoc && (
                        <LinearProgress
                          color="primary"
                          fourColor
                          variant="query"
                        />
                      )}
                    </Box>
                    <TextField
                      disabled={editar || clienteProveedor === "USUARIOS"}
                      className={clases.formD}
                      size="small"
                      label="Tipo Doc."
                      value={cliDocTipo}
                      name="cliDocTipo"
                      error={error.cliDocTipo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione un tipo de Doc.</MenuItem>
                      {["RUC", "CEDULA", "PASAPORTE"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>{" "}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label={
                        clienteProveedor === "CLIENTES"
                          ? "Nombres"
                          : "Razón Social"
                      }
                      value={cliRazonSocial}
                      name="cliRazonSocial"
                      error={error.cliRazonSocial}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]: [e.target.value]
                            .toString()
                            .toUpperCase(),
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Dirección"
                      value={cliDireccion}
                      name="cliDireccion"
                      error={error.cliDireccion}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.cliTelefono ? "error" : "primary"}
                        >
                          Telefonos:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              cliTelefono: [...item.cliTelefono, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {cliTelefono.map((precio, index) => {
                      return (
                        <TextField
                          key={index}
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id={index + "teléfono"}
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              cliTelefono: cliTelefono.map(
                                (item, indexItem) => {
                                  if (indexItem === index) {
                                    return e.target.value;
                                  } else {
                                    return item;
                                  }
                                }
                              ),
                            });
                          }}
                          label={`Teléfono #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      cliTelefono: cliTelefono.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                    <TextField
                      type="number"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Celular"
                      value={cliCelular}
                      name="cliCelular"
                      error={error.cliCelular}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.perEmail ? "error" : "primary"}
                        >
                          Correos:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              perEmail: [...item.perEmail, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {perEmail.map((precio, index) => {
                      return (
                        <TextField
                          key={index}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              perEmail: perEmail.map((item, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                          label={`Correo #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      perEmail: perEmail.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                    <>
                      {clienteProveedor === "CLIENTES" ||
                      clienteProveedor === "USUARIOS" ? (
                        <>
                          {" "}
                          <FormControl className={clases.formAllW}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="subtitle1"
                                color={error.perOtros ? "error" : "primary"}
                              >
                                Otros:
                              </Typography>
                              <IconButton
                                aria-label=""
                                size="small"
                                onClick={async () => {
                                  setitem({
                                    ...item,
                                    perOtros: [...item.perOtros, ""],
                                  });
                                }}
                              >
                                <AddCircle fontSize="small" color="secondary" />
                              </IconButton>
                            </Box>
                          </FormControl>
                          {item.perOtros ? (
                            <>
                              {" "}
                              {perOtros.map((precio, index) => {
                                return (
                                  <TextField
                                    key={index}
                                    className={clases.correo}
                                    value={precio}
                                    onKeyDown={(e) => {
                                      if (e.key === "Backspace") {
                                        return setitem({
                                          ...item,
                                          perOtros: perOtros.map(
                                            (item, indexItem) => {
                                              if (indexItem === index) {
                                                return e.target.value.substr(
                                                  0,
                                                  e.target.value.length - 1
                                                );
                                              } else {
                                                return item;
                                              }
                                            }
                                          ),
                                        });
                                      }
                                      const validos = [
                                        "a",
                                        "b",
                                        "c",
                                        "d",
                                        "e",
                                        "f",
                                        "g",
                                        "h",
                                        "i",
                                        "j",
                                        "k",
                                        "l",
                                        "m",
                                        "n",
                                        "ñ",
                                        "o",
                                        "p",
                                        "q",
                                        "r",
                                        "s",
                                        "t",
                                        "u",
                                        "v",
                                        "w",
                                        "x",
                                        "y",
                                        "z",
                                        "A",
                                        "B",
                                        "C",
                                        "D",
                                        "E",
                                        "F",
                                        "G",
                                        "H",
                                        "I",
                                        "J",
                                        "K",
                                        "L",
                                        "M",
                                        "N",
                                        "Ñ",
                                        "O",
                                        "P",
                                        "Q",
                                        "R",
                                        "S",
                                        "T",
                                        "U",
                                        "V",
                                        "W",
                                        "X",
                                        "Y",
                                        "Z",
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8",
                                        "9",
                                        "0",
                                      ];

                                      const existe = validos.find(
                                        (caracterValido) => {
                                          return caracterValido === e.key;
                                        }
                                      );

                                      if (existe) {
                                        setitem({
                                          ...item,
                                          perOtros: perOtros.map(
                                            (item, indexItem) => {
                                              if (indexItem === index) {
                                                return (
                                                  item + existe
                                                ).toUpperCase();
                                              } else {
                                                return item;
                                              }
                                            }
                                          ),
                                        });
                                      }
                                    }}
                                    onChange={(e) => {}}
                                    label={`Placa #${index + 1}`}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment>
                                          <IconButton
                                            aria-label=""
                                            onClick={() => {
                                              setitem({
                                                ...item,
                                                perOtros: perOtros.filter(
                                                  (item, indexItem) => {
                                                    return indexItem !== index;
                                                  }
                                                ),
                                              });
                                            }}
                                          >
                                            <Delete color="error" />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                );
                              })}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  </>
                ) : null}
                {datosContablesSeccion ? (
                  <>
                    {clienteProveedor === "CLIENTES" ||
                    clienteProveedor === "USUARIOS" ? null : (
                      <>
                        {" "}
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          label="Tipo proveedor"
                          value={perTipoProveedor}
                          name="perTipoProveedor"
                          error={error.perTipoProveedor}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {["BIENES", "SERVICIOS", "BIENES / SERVICIOS"].map(
                            (item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            }
                          )}
                        </TextField>
                        <FormControl
                          component="fieldset"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="span"
                              color="primary"
                              style={{
                                marginRight: ".5rem",
                                fontWeight: "bold",
                              }}
                              className={error.perObligado ? clases.error : ""}
                            >
                              Obligado
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) => handleChange(e)}
                              row
                              aria-label="gender"
                              value={perObligado}
                              name="perObligado"
                            >
                              <FormControlLabel
                                value="SI"
                                control={<Radio />}
                                label="SI"
                              />
                              <FormControlLabel
                                value="NO"
                                control={<Radio />}
                                label="NO"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>{" "}
                        <TextField
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          label="Tiempo Crédito(Días)."
                          value={perTiempoCredito}
                          name="perTiempoCredito"
                          error={error.perTiempoCredito}
                          onChange={(e) => handleChange(e)}
                        />{" "}
                        <FormControl
                          component="fieldset"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="span"
                              color="primary"
                              style={{
                                marginRight: ".5rem",
                                fontWeight: "bold",
                              }}
                              className={error.perCredito ? clases.error : ""}
                            >
                              Crédito
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) => handleChange(e)}
                              row
                              aria-label="gender"
                              value={perCredito}
                              name="perCredito"
                            >
                              <FormControlLabel
                                value="SI"
                                control={<Radio />}
                                label="SI"
                              />
                              <FormControlLabel
                                value="NO"
                                control={<Radio />}
                                label="NO"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>
                      </>
                    )}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      label="Observación"
                      value={cliObservacion}
                      multiline
                      rows={3}
                      variant={"outlined"}
                      name="cliObservacion"
                      error={error.cliObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
