import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "vaca_periodo",
    label: "Periodo de Afectación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_periodo",
    align: "center",
    ocultar: true,
  },

  {
    id: "tvacaciones",
    label: "Vacaciones",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tvacaciones",
    align: "center",
    ocultar: true,
  },

  {
    id: "tvalor",
    label: "valor",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tvalor",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "vaca_comprobante",
    label: "Comprobante",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_comprobante",
    align: "center",
    ocultar: true,
  },

  {
    id: "perDocNumero",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perDocNumero",
    align: "center",
    ocultar: true,
  },

  {
    id: "vaca_valor",
    label: "Valor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_valor",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_formaPago",
    label: "F. Pago",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_formaPago",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_periodo",
    label: "Periodo de Afectación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_periodo",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_estado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_estado",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_detalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_detalle",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_fechaDesde",
    label: "Desde",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_fechaDesde",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_fechaHasta",
    label: "Hasta",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_fechaHasta",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_fecreg",
    label: "F. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_fecreg",
    align: "center",
    ocultar: true,
  },
  {
    id: "vaca_user",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "vaca_user",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  // vaca_id: 2,
  vaca_idPersona: "", // id de persona
  perDocNumero: "", // documento de persona
  perNombres: "", // apellido + nombre
  vaca_valor: "", // input number
  vaca_formaPago: "EFECTIVO", // select ["EFECTIVO","DEPOSITO",'CHEQUE',"TRANSFERENCIA"]
  vaca_periodo: "", // Input para fecha de año y mes
  vaca_detalle: "", // textarea
  vaca_comprobante: "", // foto => png,jpg, jpeg
  vaca_fechaDesde: FechaExacta,
  vaca_empresa: "",
  vaca_user: "",
  vaca_fecreg: "",
  Todos: "",
  vaca_estado: "APROBADA",
  //NO EQUIVALENTE
  perApellidos: "",
  vaca_fechaIngreso: "",
  vaca_fechaHasta: FechaExacta,

  vaca_diaVacacion: 0,
  vaca_cantidadDiasVacaciones: 0,

  vaca_fecupd: "2024-09-09T16:13:45.000Z",
};

export const objectDefaultMultiple = {
  vaca_id: 0,
  antIdCliente: 0,
  vaca_estado: "APROBADA",
  vaca_fecreg: "",
  antRucCliente: "",
  antRazonSocialCliente: "",
  vaca_idPersona: "",
  perDocNumero: "",
  perNombres: "",
  vaca_valor: "",
  vaca_formaPago: "",
  vaca_periodo: "",
  vaca_fechaDesde: "",
  vaca_detalle: "",
  vaca_comprobante: "",
  vaca_empresa: "",
  vaca_user: "",
  idsPersonas: [
    // {
    //   perId: 1051,
    //   cedula: "0803537794",
    //   nombres: "BECERRA CORTEZ JHON ALFREDO",
    // },
  ],
  rol: [],
  rucempresa: "",
};
