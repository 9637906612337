import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import {
  Box,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  Map,
  PictureAsPdf,
  QrCode,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ButtonsAction from "../../../components/ButtonsAction";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import ModalQR from "./ModalQR";
import DialogMap from "../../../components/DialogMap";
import clienteAxios from "../../../../config/axios";
const RowAgregar = (props) => {
  const classes = useRowStyles();
  const { itemHijo, itemPadre, index, socket, guardiasTotales } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemEdit, setItemEdit] = useState({ ...itemHijo });
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("DatosOperativos");
  const [openMap, setopenMap] = useState(false);
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  const botonDisabled = () => {
    if (
      itemEdit.codigo === "" ||
      itemEdit.guardias === "" ||
      itemEdit.horasservicio === "" ||
      itemEdit.puesto === "" ||
      itemEdit.supervisor === "" ||
      itemEdit.ubicacion === ""
      // itemEdit.tipoinstalacion === "" ||
      // itemEdit.vulnerabilidades === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: async () => {
        try {
          if (!tienePermiso("editar")) {
            return alertaPermiso("editar");
          }
          console.log(`datosoperativos/validarEliminacion/${itemHijo.id}`);
          setopenConfirmDialog(true);
          // const res = await clienteAxios.get(
          //   `datosoperativos/validarEliminacion/${itemHijo.id}`
          // );
          // if (res?.data?.eliminar) {
          //   setopenConfirmDialog(true);
          // } else {
          //   mostrarAlerta(res?.data?.msg, "error");
          // }
        } catch (error) {
          console.log({ error });
        }
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    const submitItem = {
      ...itemPadre,
      tabla: "datosoperativos",
      datosoperativos: itemPadre.datosoperativos.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...itemEdit,
            color:
              !itemEdit.color || itemEdit.color === ""
                ? "#000000"
                : itemEdit.color,
          };
        } else {
          return {
            ...item,
            color:
              !item.color || itemEdit.color === "" ? "#000000" : item.color,
          };
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
      datosoperativosOld: itemPadre.datosoperativos,
    };
    socket.current.emit("client:actualizarData", submitItem);

    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "datosoperativos",
      datosoperativos: itemPadre.datosoperativos.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
      datosoperativosOld: itemPadre.datosoperativos,
    });
  };
  useEffect(() => {
    setItemEdit({ ...itemHijo });
    // eslint-disable-next-line
  }, [itemPadre]);
  return (
    <React.Fragment>
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
        row={itemPadre}
        rowHijo={itemHijo}
      />
      <DialogMap
        open={openMap}
        setopen={setopenMap}
        setitem={setItemEdit}
        item={itemEdit}
        editar={true}
        propiedad={itemEdit.coordenadas}
        nombrePropiedad={"coordenadas"}
        solover={!editar}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={itemHijo}
          botones={[
            ...(editar ? botonesEditar : botones),
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              id: 5,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            // {
            //   tooltip: "Reglamento SINCOP",
            //   texto: "",
            //   funcion: () => {
            //     window.open(
            //       `${clienteAxios.defaults.baseURL}reportes/manual_sincop?cliId=${itemPadre.perId}&rucempresa=${itemPadre.cliEmpresa}&idQr=${itemHijo.id}`
            //     );
            //   },
            //   disabled: false,
            //   Icon: PictureAsPdf,
            //   color: "error",
            //   id: 5,
            //   ocultar: false,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            //   submit: true,
            // },
          ]}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                name="codigo"
                value={itemEdit.codigo}
                onChange={(e) =>
                  setItemEdit({
                    ...itemEdit,
                    [e.target.name]: e.target.value.slice(0, 4),
                  })
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Typography
                        color={
                          itemEdit.codigo.length === 4 ? "error" : "secondary"
                        }
                      >
                        {itemEdit.codigo.length}/4
                      </Typography>
                    </React.Fragment>
                  ),
                }}
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
              placeholder="Lugar"
                value={itemEdit.ubicacion}
                name="ubicacion"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                disabled
                value={
                  itemEdit.coordenadas.latitud +
                  " " +
                  itemEdit.coordenadas.longitud
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Box htmlFor="contained-button-documento" fullWidth>
                      <InputAdornment>
                        <Tooltip title="Mostrar Mapa" arrow>
                          <Box>
                            <IconButton
                              aria-label=""
                              onClick={() => setopenMap(true)}
                            >
                              <Map color="secondary" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </InputAdornment>
                    </Box>
                  ),
                }}
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                placeholder="Nombre"
                value={itemEdit.puesto}
                name="puesto"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 },
                }}
                type="number"
                value={itemEdit.supervisor}
                name="supervisor"
                onChange={(e) => {
                  setItemEdit({
                    ...itemEdit,
                    supervisor: Number(e.target.value) < 1 ? 1 : e.target.value,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 },
                }}
                type="number"
                value={itemEdit.guardias}
                onChange={(e) => {
                  if (Number(e.target.value) < 1) {
                    setItemEdit({
                      ...itemEdit,
                      guardias: 1,
                    });
                  } else {
                    if (
                      Number(e.target.value) +
                        Number(guardiasTotales) -
                        Number(itemHijo.guardias) >
                      itemPadre.perGuardias
                    ) {
                      return mostrarAlerta(
                        `Su maximo de guardias es de ${itemPadre.perGuardias}`,
                        "error"
                      );
                    } else {
                      setItemEdit({
                        ...itemEdit,
                        guardias: e.target.value,
                      });
                    }
                  }
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemEdit.horasservicio}
                name="horasservicio"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
                type="number"
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemEdit.tipoinstalacion}
                name="tipoinstalacion"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                type="color"
                name="color"
                value={itemEdit.color}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
              {itemEdit.color}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.codigo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.ubicacion}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <IconButton aria-label="" onClick={() => setopenMap(true)}>
                <Map color="secondary" />
              </IconButton>
              {itemHijo.coordenadas
                ? itemHijo.coordenadas.latitud +
                  " " +
                  itemHijo.coordenadas.longitud
                : null}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.puesto}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.supervisor}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.guardias}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.horasservicio}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.tipoinstalacion}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.color}
            </TableCell>
          </>
        )}
        {/* {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
               
              align="center"
              className={claseCelda}
            >
              <TextField
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
    
          </>
        )} */}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
