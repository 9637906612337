import React, { useReducer, memo } from "react";
import {
  OBTENER_USUARIO,
  LOGIN_ERROR,
  LOGIN_EXITOSO,
  CERRAR_SESION,
  REGISTRO_EXITOSO,
  ELIMINAR_MENSAJE,
  EDITAR,
  //PERSONALIZACIONES
} from "../../types/index";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import io from "socket.io-client";
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";
import axiosEmpresa from "../../config/axiosEmpresa";
const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: false,
    usuario: null,
    mensaje: null,
    cargando: true,
    superUsuario: false,
    //  socket: io("http://localhost:4002/"),
    socket: io("https://backsafe.neitor.com/"),
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  //funciones
  const registrarse = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/auth/crear", datos);

      dispatch({
        type: REGISTRO_EXITOSO,
        payload: respuesta.data,
      });

      // usuarioAutenticado();
      eliminarMensaje();
    } catch (error) {
      const alerta = {
        msg: error.response.data.mensaje,
        categoria: "error",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
      eliminarMensaje();
    }
  };

  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/auth/login", datos);
      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data,
      });
      // usuarioAutenticado();
      eliminarMensaje();
    } catch (error) {
      const alerta = {
        msg: error.response.data.msg,
        categoria: "error",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
      eliminarMensaje();
    }
  };
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    const empresaheader = localStorage.getItem("empresaheader");
    const usuarioheader = localStorage.getItem("usuarioheader");
    const rolheader = localStorage.getItem("rolheader");

    await axiosEmpresa(empresaheader, usuarioheader, rolheader);

    if (token) {
      //funciion  que coloca el token por headers
      tokenAuth(token);
    }

    try {
      const respuesta = await clienteAxios.get("/auth");

      dispatch({
        type: OBTENER_USUARIO,
        payload: respuesta.data,
      });
      eliminarMensaje();
    } catch (error) {
      const alerta = {
        msg: "No tiene permiso",
        categoria: "error",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
      eliminarMensaje();
    }
  };
  const editarUsuario = async (usuario) => {
    dispatch({
      type: EDITAR,
      payload: usuario,
    });
  };
  const cambiarLogo = async (file, nombre) => {
    await clienteAxios.post("/auth/logo", {
      file,
      nombre,
    });
  };
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  const eliminarMensaje = async () => {
    dispatch({
      type: ELIMINAR_MENSAJE,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        //state
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cargando: state.cargando,
        superUsuario: state.superUsuario,
        socket: state.socket,
        //funciones
        iniciarSesion,
        registrarse,
        usuarioAutenticado,
        cerrarSesion,
        editarUsuario,
        cambiarLogo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default memo(AuthState);
