import { makeStyles } from "@mui/styles";
export const useModalStyle = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    borderRadius: "15px",
    marginTop: "4vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "70vh",
    overflow: "scroll",
    alignItems: "end",
    padding: theme.spacing(0.25),
  },
  ocultar: {
    display: "none",
  },

  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.2),
    margin: theme.spacing(0.2),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  form20PC: {
    flexGrow: 1,
    padding: theme.spacing(0.2),
    margin: theme.spacing(0.2),
    width: "17%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "47%",
    },
  },
  formD25: {
    flexGrow: 1,
    padding: theme.spacing(0.2),
    margin: theme.spacing(0.2),
    width: "23%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "47%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.1),
    margin: theme.spacing(0.1),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  formExp: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    marginLeft: "0px",
    marginRight: theme.spacing(1),
    width: "12%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "12%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "25%",
    },
  },
  formDDoc: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "35%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "35%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "70%",
    },
  },
  avatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),

    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
    margin: "0 auto",
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
  buttonTab: {
    flexGrow: "1",
    padding: "5px 5px",
  },
}));
