import React, { memo, useContext } from "react";

import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setitemPadre, itemPadre, itemHijo, index, editar: editando } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } `;

  const { mostrarAlerta } = useContext(AlertaContext);
  const [editar, seteditar] = useState(false);
  const [itemHijoEdit, setitemHijoEdit] = useState(itemHijo);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  //onChange
  const handleChange = (e) => {
    setitemHijoEdit({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.estado.trim() === "" || itemHijo.cantidad === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      const existeEstado = itemPadre.prodTotalesStock.some(
        ({ estado }) =>
          estado === itemHijoEdit.estado &&
          itemHijoEdit.estado !== itemHijo.estado
      );
      if (existeEstado) {
        return mostrarAlerta("Ya hay un stock con este estado", "error");
      }
      setitemPadre({
        ...itemPadre,
        prodTotalesStock: itemPadre.prodTotalesStock.map((item, indexItem) => {
          if (indexItem === index) {
            return trimPropsItem(itemHijoEdit);
          } else {
            return item;
          }
        }),
      });

      seteditar(false);
    } catch (error) {}
  };
  const funcEliminar = () => {
    try {
      setitemPadre({
        ...itemPadre,
        prodTotalesStock: itemPadre.prodTotalesStock.filter(
          (item, indexItem) => indexItem !== index
        ),
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijoEdit(itemHijo);
      },
      disabled: !editando,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijoEdit(itemHijo);
        // setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  return (
    <TableRow
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              funcEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ButtonsAction
        claseCelda={claseCelda}
        botones={editar ? botonesEditar : botones}
      />
      {editar ? (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              autoFocus
              type="number"
              value={itemHijoEdit.estado}
              name="estado"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={itemHijoEdit.cantidad}
              name="cantidad"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.estado}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.cantidad}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default withRouter(memo(Row));
