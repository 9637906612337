import { AddCircle, Delete } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useModalStyle } from "../../styles/styleModal";
import MuiPhoneNumber from "material-ui-phone-number";
const CamposAcumulables = ({
  error,
  propiedadTexto,
  item,
  setitem,
  tipoCampos,
  etiqueta,
  etiquetaCampo,
  disabled,
  disabledDelete,
  ocultarAdd,
}) => {
  const clases = useModalStyle();
  console.log({ item }, propiedadTexto);
  return (
    <>
      <FormControl className={clases.formAllW}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle2"
            color={error[propiedadTexto] ? "error" : "primary"}
          >
            {etiqueta}:
          </Typography>
          {ocultarAdd ? null : (
            <IconButton
              size="small"
              onClick={async () => {
                setitem({
                  ...item,
                  [propiedadTexto]: [...item[propiedadTexto], ""],
                });
              }}
            >
              <AddCircle fontSize="small" color="secondary" />
            </IconButton>
          )}
        </Box>
      </FormControl>
      {!item[propiedadTexto] || typeof item[propiedadTexto] === "string"
        ? ""
        : item[propiedadTexto].map((value, index) => {
            return tipoCampos === "date" ? (
              <TextField
                className={clases.formD}
                key={index}
                value={value}
                type={tipoCampos}
                autoComplete="false"
                size="small"
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                onChange={(e) => {
                  setitem({
                    ...item,
                    [propiedadTexto]: item[propiedadTexto].map(
                      (item, indexItem) => {
                        if (indexItem === index) return e.target.value;

                        return item;
                      }
                    ),
                  });
                }}
                label={`${etiquetaCampo} #${index + 1}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        disabled={disabledDelete}
                        onClick={() => {
                          setitem({
                            ...item,
                            [propiedadTexto]: item[propiedadTexto].filter(
                              (item, indexItem) => indexItem !== index
                            ),
                          });
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : tipoCampos === "telefono" ? (
              <MuiPhoneNumber
                defaultCountry={"ec"}
                regions={"america"}
                className={clases.formD}
                value={value}
                onChange={(e) => {
                  setitem({
                    ...item,
                    [propiedadTexto]: item[propiedadTexto].map(
                      (item, indexItem) => {
                        if (indexItem === index) return e;

                        return item;
                      }
                    ),
                  });
                }}
                type="tel"
                label={`${etiquetaCampo} #${index + 1}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        disabled={disabledDelete}
                        onClick={() => {
                          setitem({
                            ...item,
                            [propiedadTexto]: item[propiedadTexto].filter(
                              (item, indexItem) => indexItem !== index
                            ),
                          });
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextField
                className={clases.formD}
                key={index}
                value={value}
                type={tipoCampos}
                autoComplete="false"
                size="small"
                onChange={(e) => {
                  setitem({
                    ...item,
                    [propiedadTexto]: item[propiedadTexto].map(
                      (item, indexItem) => {
                        if (indexItem === index) return e.target.value;

                        return item;
                      }
                    ),
                  });
                }}
                label={`${etiquetaCampo} #${index + 1}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        disabled={disabledDelete}
                        onClick={() => {
                          setitem({
                            ...item,
                            [propiedadTexto]: item[propiedadTexto].filter(
                              (item, indexItem) => indexItem !== index
                            ),
                          });
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
    </>
  );
};

export default CamposAcumulables;
