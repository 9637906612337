export const columns = [
  {
    id: "resCliDocumento",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliDocumento",
    align: "left",
    ocultar: true,
  },

  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "resCliUbicacion",
    label: "Ubicacion",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliUbicacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "resCliPuesto",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCliPuesto",
    align: "center",
    ocultar: true,
  },

  {
    id: "resCedulaPropietario",
    label: "Cedula Prop.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCedulaPropietario",
    align: "center",
    ocultar: true,
  },
  {
    id: "resApellidoPropietario",
    label: "Nombre Prop",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resApellidoPropietario",
    align: "center",
    ocultar: true,
  },

  {
    id: "resCedula",
    label: "Cedula Arrend.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resCedula",
    align: "center",
    ocultar: true,
  },
  {
    id: "resApellidos",
    label: "Nombre Arrend.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resApellidos",
    align: "center",
    ocultar: true,
  },

  {
    id: "resFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "resUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "resUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  resId: "",

  resEstado: "ACTIVO", // ACTIVO || INACTIVO
  resCliId: "",
  resCliDocumento: "",
  resCliNombre: "",
  resCliUbicacion: "",
  resCliPuesto: "",

  resObservacion: "",
  resTipoResidente: "PROPIETARIO", // | "ARRENDATARIO", // default PROPIETARIO

  resUser: "",
  resEmpresa: "",
  resFecReg: "",
  resFecUpd: "",
  regId: 0,
  //PROPIETARIO
  resPerIdPropietario: 0,
  resCedulaPropietario: "",
  resNombrePropietario: "", // string
  resTelefonoPropietario: [], // string
  resCorreoPropietario: [],
  resApellidoPropietario: "",
  //ARRENDATARIO
  resPerId: 0,
  resCedula: "",
  resNombres: "",
  resTelefono: [],
  resApellidos: "",
  resCorreo: [], // input
  resDepartamento: [],
};
