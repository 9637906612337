import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";
import MuiImageSlider from "mui-image-slider";
import { withRouter } from "react-router";

import ButtonsAction from "./ButtonsAction";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  Dialog,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { uploadImagesHelper } from "../../../functions/uploads/subirImagenes";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
    height: "1.75rem",
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const {
    row,
    index,
    campoExport,
    item,
    setitem,
    deshabilitarbotones,
    setmovimientosData,
    movimientosData,
  } = props;
  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [open, setOpen] = useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const { usuario } = useContext(AuthContext);
  const handleChange = (e) => {
    setitemHijoEdit({
      ...itemHijoEdit,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setitemHijoEdit(row);
    // eslint-disable-next-line
  }, [item]);

  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    await clienteAxios.delete(`/historialprodentario/${itemHijoEdit.hisId}`);
    const res = movimientosData.filter((itemMantenimientoData) => {
      return itemMantenimientoData.hisId !== itemHijoEdit.hisId;
    });

    setmovimientosData(res);
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemHijoEdit(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemHijoEdit.hisFecha === "" ||
      itemHijoEdit.hisResponsable === "" ||
      itemHijoEdit.hisDetalle === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    setcargando(true);
    try {
      let resDocumentos = {
        data: {
          urls: itemHijoEdit.hisFotos,
        },
      };

      if (imagenes.length > 0) {
        resDocumentos = await uploadImagesHelper(
          imagenes,
          itemHijoEdit.hisFotos
        );
      }

      const resItem = await clienteAxios.post("/historialprodentario", {
        ...itemHijoEdit,
        prodId: item.prodId,
        hisUser: usuario.usuario,
        hisEmpresa: usuario.rucempresa,
        hisFotos: resDocumentos.data.urls,
      });

      const mantenimientosDataR = [
        ...movimientosData.map((itemMantenimientoData) => {
          if (itemMantenimientoData.hisId === itemHijoEdit.hisId) {
            return resItem.data.data;
          } else {
            return itemMantenimientoData;
          }
        }),
      ];
      mostrarAlerta("Historial Actualizado Correctamente", "success");
      setmovimientosData(mantenimientosDataR);
      setimagenes([]);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro que quiere eliminar: ${itemHijoEdit.hisResponsable}?`}
        _id={itemHijoEdit._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          itemHijoEdit={itemHijoEdit}
          row={itemHijoEdit}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={true}
          ocultarDelete={true}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
          open={open}
          setOpen={setOpen}
          imagenes={imagenes}
          setimagenes={setimagenes}
          cargando={cargando}
        />
        <Dialog
          open={Openimagen}
          onClose={() => setOpenimagen(false)}
          aria-labelledby="draggable-dialog-title"
        >
          {itemHijoEdit.hisFotos === 0 ? null : (
            <MuiImageSlider
              images={itemHijoEdit.hisFotos.map((item) => item.url)}
              fitToImageHeight={true}
            />
          )}
        </Dialog>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {itemHijoEdit.hisFotos.length === 0 ? (
            "No hay imagenes"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagen(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={itemHijoEdit.hisStatus}
                name="hisStatus"
                onChange={(e) => handleChange(e)}
                select
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["EGRESO", "INGRESO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                size="small"
                fullWidth
                value={itemHijoEdit.hisCantidad}
                name="hisCantidad"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type={"date"}
                size="small"
                id="hisFecha"
                fullWidth
                value={itemHijoEdit.hisFecha}
                name="hisFecha"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                id="hisResponsable"
                fullWidth
                value={itemHijoEdit.hisResponsable}
                name="hisResponsable"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                multiline
                rows={1}
                size="small"
                id="hisDetalle"
                fullWidth
                value={itemHijoEdit.hisDetalle}
                name="hisDetalle"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.hisStatus}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.hisCantidad}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.hisFecha}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.hisResponsable}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.hisDetalle}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
