export const columns = [
  {
    id: "bitCliDocumento",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitCliDocumento",
    align: "left",
    ocultar: true,
  },
  // {
  //   id: "bitCliUbicacion",
  //   label: "Ubicación",
  //   minWidth: 25,
  //   tipo: "string",
  //   orden: 1,
  //   propiedad: "bitCliUbicacion",
  //   align: "center",
  //   ocultar: true,
  // },
  // {
  //   id: "bitCliPuesto",
  //   label: "Puesto",
  //   minWidth: 25,
  //   tipo: "string",
  //   orden: 1,
  //   propiedad: "bitCliPuesto",
  //   align: "center",
  //   ocultar: true,
  // },
  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  // {
  //   id: "bitEstado",
  //   label: "Estado",
  //   minWidth: 25,
  //   tipo: "string",
  //   orden: 1,
  //   propiedad: "bitEstado",
  //   align: "center",
  //   ocultar: true,
  // },
  {
    id: "foto",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "foto",
    align: "center",
    ocultar: true,
  },
  // },
  {
    id: "bitTipoIngreso",
    label: "T. Ingreso",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitTipoIngreso",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitFechaIngreso",
    label: "F. Ingreso",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitFechaIngreso",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitFechaSalida",
    label: "F. Salida",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitFechaSalida",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitResCedula",
    label: "Residente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitResCedula",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitNombre_dpt",
    label: "Departamento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitNombre_dpt",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitTipoPersona",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitTipoPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitVisitanteCedula",
    label: "Visitante",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitVisitanteCedula",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitPoseeVehiculo",
    label: "Posee Vehiculo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitPoseeVehiculo",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitPlaca",
    label: "Placa",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitPlaca",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitAsunto",
    label: "Asunto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitAsunto",
    align: "center",
    ocultar: true,
  },
  {
    id: "bitUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "bitUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // bitId: "",
  bitTipoIngreso: "RECEPCION",
  bitTipoPersona: "VISITANTES",
  bitVisitanteCedula: "",
  bitVisitanteNombres: "",
  bitVisitanteTelefono: "",
  bitAsunto: "",
  bitObservacion: "",
  bitCliId: "",
  bitCliDocumento: "",
  bitCliNombre: "",
  bitCliUbicacion: "",
  bitCliPuesto: "",
  bitEmpresa: "",
  bitUser: "",
  bitEstado: "INGRESO",
  bitEstadoIngreso: "",
  bitEstadoSalida: "",
  bitAutoriza: "",
  bitResId: "",
  bitResCedula: "",
  bitResNombres: "",
  bitResTelefono: "",
  bitResDepartamento: "",
  bitResUbicacion: "",
  bitUserApruebaIngreso: "",
  bitUserApruebaSalida: "",
  regId: 0,
  bitFecReg: "",
  bitFecUpd: "",
  bitPoseeVehiculo: "NO",
  bitPlaca: "",
  bitFotoCedulaFrontal: "",
  bitFotoCedulaReverso: "",
  bitFotoVehiculo: "",
  bitFotoPersona: "",
  bitNombre_dpt: "",
  bitNumero_dpt: "",
  bitInformacionVehiculo: {
    dni: "",
    fullname: "",
    model: "",
  },
};
