import * as IconosImport from "@mui/icons-material";
import clienteAxios from "./axios";
export const secondary = "#263238";
export const serverphp = "https://syscontable.neitor.com/";
export const socketUrl = "https://backsafe.neitor.com/";
export const permisos = [
  {
    id: "nombre",
    label: "nombre",
    minWidth: 150,
  },
  {
    id: "añadir",
    label: "añadir",
    minWidth: 150,
  },

  {
    id: "editar",
    label: "editar",
    minWidth: 150,
  },

  {
    id: "eliminar",
    label: "eliminar",
    minWidth: 150,
  },
];

export const fecha = `${new Date().getFullYear()}-${(
  "0" +
  (new Date().getMonth() + 1)
).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`;

export const permisoDenagado = "No tienes permiso para esta accion";

export const replaceCaracteres = (string) => {
  try {
    let res = string.replace(/(\r\n|\n|\r)/gm, " ");
    res = res.replace(/(["]+)/gm, "");
    res = res.replace(/([']+)/gm, "");
    return res;
  } catch (error) {}
};

export const trimPropsItem = (item) => {
  const json = item;
  Object.entries(item).forEach((itemProps) => {
    if (typeof itemProps[1] === "string") {
      const trimString = itemProps[1].trim();
      json[itemProps[0]] = replaceCaracteres(trimString);
    }
  });

  return json;
};
export const FechaExacta = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
)
  .toISOString()
  .split("T")[0];
export const FechaExactaConHoras = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
)
  .toISOString()
  .substring(0, 16);
export const mesAñoActual = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
)
  .toISOString()
  .substring(0, 7);

export const quitarTildes = (cadena) => {
  var mapaAcentos = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
  };

  return cadena.replace(/[áéíóúÁÉÍÓÚ]/g, function(letra) {
    return mapaAcentos[letra];
  });
};

export const navegadorCompatible =
  window.navigator.userAgent.indexOf("Chrome") > -1 ||
  window.navigator.userAgent.indexOf("Brave") > -1;
export const getMonths = (
  desdeMesPasado,
  añosAnteriores = 0,
  añosFuturos = 0
) => {
  let FechaExacta = new Date();
  let anioActual = FechaExacta.getFullYear();
  let inicio = anioActual - añosAnteriores;
  let fin = anioActual + añosFuturos;
  let meses = [];

  for (let anio = inicio; anio <= fin; anio++) {
    for (
      let mes = desdeMesPasado ? new Date().getMonth() - 1 : 0;
      mes < 12;
      mes++
    ) {
      meses.push(`${anio}-${(mes + 1).toString().padStart(2, "0")}`);
    }
  }

  return meses;
};
export const hacerIcono = (icono, color = "inherit") => {
  if (icono === "" || !icono) {
    return;
  }
  const Icono = IconosImport[icono];
  if (!Icono) {
    return null;
  }
  return <Icono color={color} />;
};
export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

