import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ToolBarTable from "../../../../components/ToolBarTable";
import { useSimpleTable } from "../../../../hooks/useSimpleTable";
import TablaCabecera from "../../../../components/TablaCabecera";
import RowAgregarVinculacion from "./RowAgregarVinculacion";
import RowVinculacion from "./RowVinculacion";
import { AddCircle } from "@mui/icons-material";
import clienteAxios from "../../../../../config/axios";

const Vinculacion = ({ id, estadoRequest }) => {
  const {
    agregandoVinculacion,
    setagregandoVinculacion,
    pageVinculacion,
    // setPageVinculacion,
    rowsPerPageVinculacion,
    // setRowsPerPageVinculacion,
    handleChangePageVinculacion,
    handleChangeRowsPerPageVinculacion,
  } = useSimpleTable("Vinculacion");
  const [vinculacionesData, setvinculacionesData] = useState([]);
  const getData = async () => {
    try {
      const resvinculaciones = await clienteAxios.get(
        `/fechasvinculacion/${id}`
      );
      console.log(resvinculaciones);

      setvinculacionesData(resvinculaciones.data);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Paper sx={{ width: "100%" }}>
      <ToolBarTable
        count={vinculacionesData.length}
        rowsPerPage={rowsPerPageVinculacion}
        page={pageVinculacion}
        handleChangePage={handleChangePageVinculacion}
        handleChangeRowsPerPage={handleChangeRowsPerPageVinculacion}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[
          {
            tooltip: "Agregar Vinculacion",
            texto: "Agregar Vinculacion",
            funcion: () => {
              setagregandoVinculacion(true);
            },
            disabled: false,
            Icon: AddCircle,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[]}
      />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TablaCabecera
              columns={[
                {
                  id: "fecVinculacion",
                  align: "fecVinculacion",
                  minWidth: 100,
                  tipo: "string",
                  label: "F. Vinculación",
                },
                {
                  id: "fecRetiro",
                  align: "fecRetiro",
                  minWidth: 100,
                  tipo: "string",
                  label: "F. Retiro",
                },
                {
                  id: "fecReg",
                  align: "fecReg",
                  minWidth: 100,
                  tipo: "string",
                  label: "F. Reg",
                },
                {
                  id: "fecRegUpdate",
                  align: "fecRegUpdate",
                  minWidth: 100,
                  tipo: "string",
                  label: "F. Update",
                },
                {
                  id: "fecUser",
                  align: "fecUser",
                  minWidth: 100,
                  tipo: "string",
                  label: "User",
                },
                {
                  id: "fecUserUpdate",
                  align: "fecUserUpdate",
                  minWidth: 100,
                  tipo: "string",
                  label: "U. User",
                },
              ]}
              habilitarOrdenar={false}
            />
          </TableHead>

          <TableBody component={"div"}>
            {agregandoVinculacion ? (
              <RowAgregarVinculacion
                setagregar={setagregandoVinculacion}
                estadoRequest={estadoRequest}
                id={id}
                setvinculacionesData={setvinculacionesData}
              />
            ) : null}
            {vinculacionesData
              .slice(
                pageVinculacion * rowsPerPageVinculacion,
                pageVinculacion * rowsPerPageVinculacion +
                  rowsPerPageVinculacion
              )
              .map((rowHijo, index) => {
                return (
                  <RowVinculacion
                    id={id}
                    estadoRequest={estadoRequest}
                    setvinculacionesData={setvinculacionesData}
                    rowHijo={rowHijo}
                    index={index}
                    key={rowHijo.fecId}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Vinculacion;
