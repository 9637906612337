import { useContext } from "react";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";

export const useGetNameUser = () => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const handleGetNameUser = async (documento) => {
    if (!documento) {
      return;
    }
    try {
      const resUser = await clienteAxios.get(
        `/proveedores/usuario/${documento}`
      );
      mostrarAlerta(resUser.data.data.nombre, "success");
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };

  return { handleGetNameUser };
};
