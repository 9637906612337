import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import TablaCabecera from "../../components/TablaCabecera";
import RowAgregarItem from "../Componentes/RowItems/RowAgregarItem";
import RowItem from "../Componentes/RowItems/RowItem";
import { columnasHijo } from "../data";
import { useEffect } from "react";
import { reducer } from "../../functions/funciones";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import ToolBarTable from "../../components/ToolBarTable";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const {
    agregandoDato,
    setagregandoDato,
    pageDato,
    // setPageDato,
    rowsPerPageDato,
    // setRowsPerPageDato,
    handleChangePageDato,
    handleChangeRowsPerPageDato,
  } = useSimpleTable("Dato");

  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // GUARDIAS TOTALES
  const [guardiasTotales, setguardiasTotales] = useState(
    item.datosoperativos.length === 0
      ? null
      : item.datosoperativos
          .map((row) => {
            return Number(row.guardias);
          })
          .reduce(reducer)
          .toFixed(0)
  );

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "datosoperativos",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        estUser: usuario.usuario,
        estEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
        datosoperativosOld: row.datosoperativos,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };

  useEffect(() => {
    setitem(row);
    setguardiasTotales(
      row.datosoperativos.length === 0
        ? null
        : row.datosoperativos
            .map((row) => {
              return Number(row.guardias);
            })
            .reduce(reducer)
            .toFixed(0)
    );
    // eslint-disable-next-line
  }, [row]);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Expandir",
              texto: row.datosoperativos.length,
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.doc}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {row.razonsocial}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Paper>
                <ToolBarTable
                  count={row.datosoperativos.length}
                  rowsPerPage={rowsPerPageDato}
                  page={pageDato}
                  handleChangePage={handleChangePageDato}
                  handleChangeRowsPerPage={handleChangeRowsPerPageDato}
                  ocultarPaginacion={false}
                  simplePagination={true}
                  botonesIzquierdos={[
                    {
                      tooltip: "Agregar",
                      texto: "Agregar",
                      funcion: () => {
                        setagregandoDato(true);
                      },
                      disabled: Number(guardiasTotales) >= row.perGuardias,
                      Icon: AddCircle,
                      color: "primary",
                      id: 1,
                      ocultar: false,
                      tipo: "boton",
                      variante: "contained",
                      size: "medium",
                      sx: {},
                    },
                  ]}
                  botonesDerechos={[]}
                />

                <TableContainer>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TablaCabecera
                        columns={columnasHijo}
                        habilitarOrdenar={false}
                      />
                    </TableHead>
                    <TableBody component={"div"}>
                      {agregandoDato ? (
                        <RowAgregarItem
                          itemPadre={row}
                          setagregandoDato={setagregandoDato}
                          socket={socket}
                          guardiasTotales={guardiasTotales}
                        />
                      ) : null}
                      {row.datosoperativos
                        .slice(
                          pageDato * rowsPerPageDato,
                          pageDato * rowsPerPageDato + rowsPerPageDato
                        )
                        .map((itemHijo, index) => {
                          return (
                            <RowItem
                              itemHijo={itemHijo}
                              itemPadre={row}
                              index={index}
                              key={index}
                              socket={socket}
                              guardiasTotales={guardiasTotales}
                            />
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        >
                          {guardiasTotales} / {row.perGuardias}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                   
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
