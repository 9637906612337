import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "antPeriodo",
    label: "Periodo de Afectación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antPeriodo",
    align: "center",
    ocultar: true,
  },

  {
    id: "tanticipos",
    label: "Anticipos",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tanticipos",
    align: "center",
    ocultar: true,
  },

  {
    id: "tvalor",
    label: "valor",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tvalor",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "antComprobante",
    label: "Comprobante",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antComprobante",
    align: "center",
    ocultar: true,
  },
  {
    id: "antRucCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antRucCliente",
    align: "left",
    ocultar: true,
  },

  {
    id: "antDocuPersona",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "antValor",
    label: "Valor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antValor",
    align: "center",
    ocultar: true,
  },
  {
    id: "antFormaPago",
    label: "F. Pago",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antFormaPago",
    align: "center",
    ocultar: true,
  },
  {
    id: "antPeriodo",
    label: "Periodo de Afectación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antPeriodo",
    align: "center",
    ocultar: true,
  },
  {
    id: "antEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "antDetalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antDetalle",
    align: "center",
    ocultar: true,
  },
  {
    id: "antFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antFecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "antFecReg",
    label: "F. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "antUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "antUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // antId: 2,
  antIdPersona: "", // id de persona
  antDocuPersona: "", // documento de persona
  antNomPersona: "", // apellido + nombre
  antValor: "", // input number
  antNombreBono: "",
  antFormaPago: "EFECTIVO", // select ["EFECTIVO","DEPOSITO",'CHEQUE',"TRANSFERENCIA"]
  antPeriodo: "", // Input para fecha de año y mes
  antDetalle: "", // textarea
  antComprobante: "", // foto => png,jpg, jpeg
  antFecha: FechaExacta,
  antEmpresa: "",
  antUser: "",
  antFecReg: "",
  Todos: "",
  antTipo: "",
  antEstado: "APROBADA",
  // rolpCodigo: "",
  // rolpIdCliente: "",
  // antDocuPersona: "",
  // rolpNomCliente: "",
  // rolpCedula: "",
  // rolpIdPersona: "",
  // rolpNombres: "",
  // rolpFoto: "",
  // rolpSueldo: "",
  // rolpCargo: "",
  // rolpCiudad: "",
  // rolpDiaLabo: "30",
  // rolpPerEstatus: "",
  // rolpEstado: "PENDIENTE",
  // antPeriodo: "",
  // rolpNumCuenta: "",
  // rolpBancoRemu: "",
  // rolpEmail: [],
  // rolpIngresos: [],
  // rolpEgresos: [],
  // antFecReg: "",
  // antUser: "",
  // Todos: "",
  // rolpDocTipo: "",
  // rolpTipoCuenta: "",
  // rolpFormaPago: "",
  // rolpTelefono: "",
  // tguardias: 0,
  // tsueldo: "",
  // rolpLiquidaciones: [],
  // rolpOtros: [],
};
export const objectDefaultMultiple = {
  antId: 0,
  antIdCliente: 0,
  antTipo: "Anticipos",
  antEstado: "APROBADA",
  antFecReg: "",
  antRucCliente: "",
  antRazonSocialCliente: "",
  antIdPersona: "",
  antDocuPersona: "",
  antNomPersona: "",
  antValor: "",
  antFormaPago: "",
  antPeriodo: "",
  antFecha: "",
  antDetalle: "",
  antComprobante: "",
  antEmpresa: "",
  antUser: "",
  idsPersonas: [
    // {
    //   perId: 1051,
    //   cedula: "0803537794",
    //   nombres: "BECERRA CORTEZ JHON ALFREDO",
    // },
  ],
  rol: [],
  rucempresa: "",
};
