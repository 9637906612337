import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Collapse,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";

import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";
import AuthContext from "../../../Context/Auth/AuthContext";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    socket,
    ObjectoActivo,
    changeObjetoActivoState,
    // alertaPermiso,
    // tienePermiso,
  } = props;

  const { usuario } = useContext(AuthContext);
  const desdeOtroLugarVenta = true;
  const [openModalEmail, setopenModalEmail] = useState(false);
  const [celComprimida, setcelComprimida] = useState({});
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    // if (!tienePermiso("eliminar")) {
    //   return alertaPermiso("eliminar");
    // }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "venta",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        venUser: usuario.usuario,
        venEmpresa: usuario.rucempresa,
        venId: row.venId,
        venRucCliente: row.venRucCliente,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
      venOption: "3",
      venProductosAntiguos: row.venProductos,
      venKilometrajeInicio: row.venKilometrajeInicio
        ? row.venKilometrajeInicio
        : "",
      venKilometrajeFinal: row.venKilometrajeFinal
        ? row.venKilometrajeFinal
        : "",
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [seccion, setseccion] = useState({
    CuentasPorCobrarSeccion: true,
  });
  const { CuentasPorCobrarSeccion } = seccion;
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.venNumFactura}?`}
        contenido={`La venta ${row.venNumFactura} será eliminada, no podra ser recuperadá`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setopen={setopen}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="empEstado"
          setopenModalEmail={setopenModalEmail}
          expandir={expandir}
          setexpandir={setexpandir}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
        />{" "}
        {/* <TableCell
          padding="none"
          size="small"
          align="center"
          style={{
            color:
              row.estado === "AUTORIZADO" ||
              row.estado === "PROCESADO" ||
              row.estado === "GASTOS PERSONALES"
                ? "green"
                : row.estado === "IMPORTADO"
                ? "#0288d1"
                : row.estado === "ANULADA"
                ? "#f44336"
                : //
                row.estado === "GENERAR XML"
                ? "#ffa726"
                : "#ffa726",
            fontWeight: "bold",
          }}
          className={classes.celda}
        >
          {row.estado}
        </TableCell> */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.venNumFactura}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.venRucCliente}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          {row.venNomCliente}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.venFormaPago}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.venFechaFactura}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.venFacturaCredito}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.venTotal}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={
            row.venEstado === "SIN AUTORIZAR"
              ? `${classes.celda} ${classes.hover}`
              : `${classes.celda} `
          }
          onClick={() => {
            if (row.venEstado === "SIN AUTORIZAR") {
              socket.current.emit("client:guardarData", {
                ...row,
                venOption: "3",
                optionDocumento: row.venTipoDocumento,
                rucempresa: usuario.rucempresa,
                rol: usuario.rol,
                venUser: usuario.usuario,
                venEmpresa: usuario.rucempresa,
                venProductosAntiguos: row.venProductos,
                tabla: "venta",
              });
            }
          }}
        >
          <Tooltip
            title={row.venEstado === "SIN AUTORIZAR" ? "AUTORIZAR" : ""}
            arrow
          >
            <Typography
              variant="inherit"
              color="secondary"
              component={"span"}
              sx={{
                color:
                  row.venEstado === "EN PROCESO"
                    ? "orange"
                    : row.venEstado === "APROBADO"
                    ? "green"
                    : row.venEstado === "NO APROBADO"
                    ? "blue"
                    : row.venEstado === "ANULADO"
                    ? "red"
                    : "black",
              }}
            >
              {row.venEstado}
            </Typography>
          </Tooltip>
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          style={{
            color: row.venEnvio === "ENVIADO" ? "green" : "#f44336",
            fontWeight: "bold",
          }}
        >
          {row.venEnvio}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={`${classes.celda} ${classes.hover}`}
          onClick={() =>
            setcelComprimida({
              ...celComprimida,
              venObservacion:
                row.venObservacion.length > 30
                  ? !celComprimida["venObservacion"]
                  : celComprimida["venObservacion"],
            })
          }
        >
          {!celComprimida["venObservacion"] ? (
            <>
              {row.venObservacion.length > 30
                ? `${row.venObservacion.substr(0, 30)}...`
                : `${row.venObservacion.substr(0, 30)}`}
            </>
          ) : null}
          <Collapse
            in={celComprimida["venObservacion"]}
            timeout="auto"
            unmountOnExit
          >
            {row.venObservacion}
          </Collapse>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.venUser}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.venFecReg).toLocaleString("es-US", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>{" "}
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
