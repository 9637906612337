import React, { useContext, useEffect, useState } from "react";

// @mui/material components
// import { makeStyles } from "@mui/styles";
import {
  IconButton,
  Box,
  Badge,
  MenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Avatar,
  Button,
  Popper,
  Fade,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// @material-ui/icons
import {
  Notifications,
  NotificationsActive,
  NotificationsOutlined,
} from "@mui/icons-material";
import SiluetaPolice from "../../../assets/img/Recurso 1.png";
import AuthContext from "../../../Context/Auth/AuthContext.js";
// import guardiaPng from "../../../assets/img/guardiaPng.png";
import clienteAxios from "../../../config/axios.js";
import { trimPropsItem } from "../../../config/const.js";
import { NavLink, withRouter } from "react-router-dom";
import Perfil from "./Perfil";
import Encuestas from "./Encuestas";
const NotificactionsButtons = (props) => {
  const { usuario, socket, cerrarSesion } = useContext(AuthContext);
  const [openEncuestas, setopenEncuestas] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  // NOTIFICACION 1
  const [openNotificacion1, setOpenNotificacion1] = React.useState(false);
  const [anchorElNotificacion1, setAnchorElNotificacion1] = React.useState(
    null
  );
  const handleClickNotificacion1 = (event) => {
    setAnchorElNotificacion1(event.currentTarget);
    setOpenNotificacion1((previousOpen) => !previousOpen);
  };
  const canBeOpenNotificacion1 =
    openNotificacion1 && Boolean(anchorElNotificacion1);
  const idNotificacion1 = canBeOpenNotificacion1
    ? "transition-popperNotificacion1"
    : undefined;

  // NOTIFICACION 2
  const [openNotificacion2, setOpenNotificacion2] = React.useState(false);
  const [anchorElNotificacion2, setAnchorElNotificacion2] = React.useState(
    null
  );
  const handleClickNotificacion2 = (event) => {
    setAnchorElNotificacion2(event.currentTarget);
    setOpenNotificacion2((previousOpen) => !previousOpen);
  };
  const canBeOpenNotificacion2 =
    openNotificacion2 && Boolean(anchorElNotificacion2);
  const idNotificacion2 = canBeOpenNotificacion2
    ? "transition-popperNotificacion2 "
    : undefined;

  const [openPerfil, setopenPerfil] = useState(false);

  const handleEditar = (notificacion) => {
    socket.emit(
      "client:actualizarData",
      trimPropsItem({
        ...notificacion,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        notUser: usuario.usuario,
        notEmpresa: usuario.rucempresa,
        tabla: "notificacionleido",
      })
    );
  };
  const [notificacionesData, setnotificacionesData] = useState({
    notificacion1: [],
    notificacion2: [],
    estado: false,
  });
  const [itemEdit, setitemEdit] = useState(false);
  const [nuevoItem, setnuevoItem] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const resNotificaciones = await clienteAxios.get(
        "/notificaciones/listado"
      );

      if (
        resNotificaciones.data.data.notificacion1 ||
        resNotificaciones.data.data.notificacion2
      ) {
        setnotificacionesData({ estado: true, ...resNotificaciones.data.data });
      }
    };
    if (!notificacionesData.estado) {
      getData();
    }
    // eslint-disable-next-line
  }, []);
  const notificacionesDataRef = React.useRef(notificacionesData);
  useEffect(() => {
    // eslint-disable-next-line
  }, [notificacionesDataRef, socket]);

  useEffect(() => {
    socket.on("server:actualizadoExitoso", async (data) => {
      if (usuario.usuario !== data.notNotificacionPertenece) {
        return;
      }
      setitemEdit(data);
      //(data);
      return () => {
        socket.close();
      };
    });
    socket.on("server:nuevanotificacion", async (data) => {
      if (usuario.usuario !== data.notNotificacionPertenece) {
        return;
      }
      if (usuario.rucempresa !== data.notEmpresa) {
        return;
      }
      setnuevoItem(data);
      //(data);
      return () => {
        socket.close();
      };
    });
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => {
    if (itemEdit) {
      if (itemEdit.notTipo === "ACTIVIDAD" || itemEdit.notTipo === "CONSIGNA") {
        const resNotificaciones = notificacionesData.notificacion1.map(
          (itemNotificacion) => {
            if (itemNotificacion.notId === itemEdit.notId) {
              return itemEdit;
            } else {
              return itemNotificacion;
            }
          }
        );
        setnotificacionesData({
          ...notificacionesData,
          notificacion1: resNotificaciones,
        });
      } else {
        const resNotificaciones = notificacionesData.notificacion2.map(
          (itemNotificacion) => {
            if (itemNotificacion.notId === itemEdit.notId) {
              return itemEdit;
            } else {
              return itemNotificacion;
            }
          }
        );

        setnotificacionesData({
          ...notificacionesData,
          notificacion2: resNotificaciones,
        });
      }
      //refresh the local state
      setitemEdit(false);
    }

    if (nuevoItem) {
      if (
        nuevoItem.notTipo === "ACTIVIDAD" ||
        nuevoItem.notTipo === "CONSIGNA"
      ) {
        setnotificacionesData({
          ...notificacionesData,
          notificacion1: [nuevoItem, ...notificacionesData.notificacion1],
        });
      } else {
        setnotificacionesData({
          ...notificacionesData,
          notificacion2: [nuevoItem, ...notificacionesData.notificacion2],
        });
      }
      //refresh the local state
      setnuevoItem(false);
    }
    // eslint-disable-next-line
  }, [itemEdit, nuevoItem]);

  const [mensaje, setmensaje] = useState({});
  useEffect(() => {
    const getMensaje = async () => {
      try {
        const res = await clienteAxios.get(
          "/actualizaciones/mostrarMensaje/web"
        );
        console.log({ res });
        setmensaje(res?.data || {});
      } catch (error) {
        console.log({ error });
      }
    };
    getMensaje();
  }, []);

  return (
    <Box display="flex" alignItems={"center"}>
      <Perfil open={openPerfil} setopen={setopenPerfil} />
      <Encuestas open={openEncuestas} setopen={setopenEncuestas} />
      {/* NOTIFICACIONES 1 */}
      <Box display="flex" alignItems="center">
        <IconButton
          aria-describedby={idNotificacion1}
          type="button"
          onClick={handleClickNotificacion1}
        >
          <Badge
            badgeContent={
              notificacionesData.notificacion1.filter(
                (itemNotificacion) => itemNotificacion.notVisto === "NO"
              ).length
            }
            color="error"
          >
            <NotificationsActive
              color={props.mobile ? "secondary.dark" : "secondary.dark"}
            />
          </Badge>
        </IconButton>
      </Box>

      <Popper
        sx={{ zIndex: "3500" }}
        id={idNotificacion1}
        open={openNotificacion1}
        anchorEl={anchorElNotificacion1}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => setOpenNotificacion1(false)}
              >
                <MenuList
                  style={{
                    minWidth: "30rem",
                    maxHeight: "20rem",
                    overflow: "scroll",
                  }}
                  role="menu"
                  color="primary"
                >
                  {[...notificacionesData.notificacion1].map((item, key) => {
                    return (
                      <Box
                        onClick={() => {
                          if (item.notVisto === "SI") {
                            return;
                          }
                          setOpenNotificacion1(false);
                          setopenEncuestas(true);
                        }}
                        maxWidth={"30rem"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          minHeight: "2.5rem",

                          backgroundColor:
                            item.notVisto === "NO" ? "#096d9c" : "white",
                          borderBottom: "1px solid #e1e1e1",
                          padding: "1rem",
                        }}
                      >
                        <Box maxWidth={"23rem"} minWidth={"23rem"}>
                          <Typography
                            component={"p"}
                            fontSize=".9rem"
                            fontWeight="bold"
                            color={
                              item.notVisto === "NO"
                                ? "common.white"
                                : "secondary"
                            }
                          >
                            {item.notTitulo}
                          </Typography>
                          <Typography
                            component={"p"}
                            fontSize=".8rem"
                            color={
                              item.notVisto === "NO"
                                ? "common.white"
                                : "secondary"
                            }
                          >
                            {item.notNombrePersona}
                          </Typography>
                          <Typography
                            component={"p"}
                            fontSize=".8rem"
                            color={
                              item.notVisto === "NO"
                                ? "common.white"
                                : "secondary"
                            }
                          >
                            {item.notDocuPersona}
                          </Typography>
                          <Typography
                            component={"p"}
                            fontSize=".8rem"
                            color={
                              item.notVisto === "NO"
                                ? "common.white"
                                : "secondary"
                            }
                          >
                            {item.notContenido}
                          </Typography>
                          <Typography
                            component={"p"}
                            fontSize=".8rem"
                            color={
                              item.notVisto === "NO"
                                ? "common.white"
                                : "secondary"
                            }
                          >
                            {item.notUser}
                          </Typography>
                        </Box>

                        <Box>
                          <Box>
                            <Avatar
                              sx={{ minWidth: "3rem", minHeight: "3rem" }}
                              src={item.notFoto}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* NOTIFICACIONES 2 */}
      <Box mr={2}>
        <IconButton
          aria-describedby={idNotificacion2}
          type="button"
          onClick={handleClickNotificacion2}
        >
          <Badge
            badgeContent={
              notificacionesData.notificacion2.filter(
                (itemNotificacion) => itemNotificacion.notVisto === "NO"
              ).length
            }
            color="error"
          >
            <Notifications color={props.mobile ? "primary" : "secondary"} />
          </Badge>
        </IconButton>
      </Box>
      <Popper
        sx={{ zIndex: "3500" }}
        id={idNotificacion2}
        open={openNotificacion2}
        anchorEl={anchorElNotificacion2}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => setOpenNotificacion2(false)}
              >
                <MenuList
                  style={{
                    minWidth: "15rem",
                    maxHeight: "20rem",
                    overflow: "scroll",
                  }}
                  role="menu"
                  color="primary"
                >
                  {[...notificacionesData.notificacion2].map((item, key) => {
                    return (
                      <NavLink
                        to={
                          item.notInformacion.urlweb
                            ? item.notInformacion.urlweb
                            : ""
                        }
                        onClick={() => {
                          if (item.notVisto === "SI") {
                            return;
                          }
                          handleEditar(item);
                        }}
                      >
                        <Box
                          maxWidth={"30rem"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            minHeight: "2.5rem",

                            backgroundColor:
                              item.notVisto === "NO" ? "#096d9c" : "white",
                            borderBottom: "1px solid #e1e1e1",
                            padding: "",
                          }}
                        >
                          <Box maxWidth={"23rem"} minWidth={"23rem"}>
                            <Typography
                              component={"p"}
                              fontSize=".9rem"
                              fontWeight="bold"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notTitulo}
                            </Typography>
                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notDocuPersona} - {item.notNombrePersona}
                            </Typography>

                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notContenido}
                            </Typography>
                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notUser}
                            </Typography>
                          </Box>

                          <Box>
                            <Box>
                              <Avatar
                                sx={{ minWidth: "3rem", minHeight: "3rem" }}
                                src={item.notFoto}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </NavLink>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* SUBMENU */}
      <Badge
        color="warning"
        badgeContent={
          (mensaje.tipo === "ACTUALIZACION" &&
            usuario.actualizacion_web === 1) ||
          mensaje.tipo === "MANTENIMIENTO"
            ? mensaje.detalle
            : null
        }
        sx={{ ":hover": { cursor: "pointer" } }}
        componentsProps={{
          badge: {
            style: { padding: "1rem" },
            onClick: async () => {
              if (usuario.actualizacion_web === 0) {
                return;
              }
              const res = await clienteAxios.put(
                "actualizaciones/actualizar_estado",
                {
                  tipo: "web",
                }
              );
              console.log({ res });
              window.location.reload(true);
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Button
          color="inherit"
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          <Avatar
            alt={usuario.nombres}
            style={{ marginRight: ".3rem", marginLeft: ".3rem" }}
            src={usuario.foto}
          />

          {usuario.nombre}
        </Button>
      </Badge>
      <Popper
        sx={{ zIndex: "3500" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList role="menu" color="primary">
                  {mensaje.tipo === "ACTUALIZACION" &&
                    usuario.actualizacion_web === 1 && (
                      <MenuItem
                        sx={{ color: (theme) => theme.palette.warning.main }}
                        onClick={async () => {
                          const res = await clienteAxios.put(
                            "actualizaciones/actualizar_estado",
                            {
                              tipo: "web",
                            }
                          );
                          console.log({ res });
                          window.location.reload(true);
                        }}
                      >
                        Actualizar
                      </MenuItem>
                    )}

                  <MenuItem
                    onClick={() => {
                      props.history.push("/Seguridad/Dashboard");
                    }}
                  >
                    Inicio
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setopenEncuestas(true);
                    }}
                  >
                    Encuestas
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setopenPerfil(true);
                    }}
                  >
                    Mis Datos
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      cerrarSesion();

                      props.history.push("/login");
                    }}
                  >
                    Cerrar Sesión
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(NotificactionsButtons);
