import { FechaExacta } from "../../config/const";

// COLUMNAS IMPLEMENTARIOS
export const columnsImplementarios = [
  {
    id: "prodTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodSerie",
    label: "Serie",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodMarca",
    label: "Marca",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodMarca",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodModelo",
    label: "Modelo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodModelo",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodStock",
    label: "Stock",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodStock",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodTalla",
    label: "Talla",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodTalla",
    align: "center",
    ocultar: true,
  },

  {
    id: "prodFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodUser",
    align: "center",
    ocultar: true,
  },
];
// COLUMNAS ARMAS
export const columnsArmas = [
  {
    id: "prodSerie",
    label: "Serie",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodMarca",
    label: "Marca",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodMarca",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodModelo",
    label: "Modelo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodModelo",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodStock",
    label: "Stock",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodStock",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodTipoArma",
    label: "TipoArma",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodTipoArma",
    align: "center",
    ocultar: true,
  },

  {
    id: "prodFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodUser",
    align: "center",
    ocultar: true,
  },
];

// COLUMNAS MUNICIONES
export const columnsMuniciones = [
  {
    id: "prodSerie",
    label: "Serie",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodMarca",
    label: "Marca",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodStock",
    label: "Stock",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },

  {
    id: "prodFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSerie",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  // prodId: 1,
  prodTipo: "VESTIMENTAS", // ARMAS, MUNICIONES, EQUIPOS
  prodSerie: "0",
  prodNombre: "",
  prodEstado: "ACTIVA",
  prodObservacion: "",
  prodMaterial: "",
  prodPrecioUnitario: 1,
  prodMarca: "",
  prodModelo: "",
  prodTalla: 0,
  prodClase: "",
  prodTipoArma: "",
  prodCalibre: "",
  prodColor: "", // input
  prodUso: "", // input
  prodStatus: "", // input
  condiciones: ["NUEVO"], // MULTISELECT ('NUEVO','USADO','OPERABLE','BUEN ESTADO','DEFECTUOSO')
  prodFecValidacion: FechaExacta, // input date
  prodFecCaducidad: FechaExacta, // input date

  prodPermisoPdf: "", // input file
  // prodComprobantePdf: "", // input file
  prodDocumento: "", // input file
  prodFotos: [],

  stock_disponible: 50, // not input
  prodFecUpd: "", // not input
  prodFecReg: "", // not input

  prodEmpresa: "PRUEBA",
  prodUser: "admin",
  rol: [],
  rucempresa: "PRUEBA",
  tabla: "productos",
};
