export const columns = [
  {
    id: "cliDocTipo",
    label: "Tipo Doc",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "cliDocNumero",
    label: "Documento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "cliRazonSocial",
    label: "Nombres",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "left",
    ocultar: false,
  },
  {
    id: "cliTelefono",
    label: "Teléfono",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "cliCelular",
    label: "Celular",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "perObligado",
    label: "Obligado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "perEmail",
    label: "Email",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "cliUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecReg",
    align: "center",
    ocultar: false,
  },
  {
    id: "cliFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "cliFecReg",
    align: "center",
    ocultar: false,
  },
];

export const ObjectoActivoDefault = {
  // CLIENTE
  //   cliId: 1,
  perOtros: ["ZZZ9999"],
  perNickname: "",
  perObligado: "NO",
  perTipoProveedor: "BIENES",
  perTiempoCredito: "0",
  perCredito: "NO",

  perGuardias: "1",
  documentos: {},
  cliTiempoInicioContrato: "",
  cliTiempoFinalContrato: "",
  perPerfil: [],
  cliDocTipo: "RUC",
  cliDocNumero: "",
  cliRazonSocial: "",
  cliNombreComercial: "",
  cliNombres: "",
  cliDireccion: "",
  cliInstitucion: "PUBLICA",
  cliUbicacion: {
    longitud: -79.178298,
    latitud: -0.254916,
  },
  cliTelefono: [],
  cliCelular: "",
  perEmail: [],
  cliAdministradorContrato: "",
  cliCiudad: "",
  cliArmasAsignadas: [],
  cliDatosOperativos: [],
  cliEquiposAsignados: [],
  cliOtrosEquipos: "",
  cliEmpresa: [],
  perPais: "ECUADOR",
  perProvincia: "SANTO DOMINGO DE LOS TSÁCHILAS",
  perCanton: "SANTO DOMINGO",
  perEstado: "ACTIVO",
  perFoto: "",
  perLogo: "",
  cliUser: "",
  cliObservacion: "",
  cliFecReg: "",
  Todos: "",
};
