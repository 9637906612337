import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../../components/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import ModalB from "../../components/ModalB";
import DialogOpciones from "../../components/DialogOpciones";
import { Add } from "@mui/icons-material";
import { SocketProvider } from "../../../Context/WhatsappSocket/SocketContext";

const Contabilidad = (props) => {
  // DATA DEL STATE

  const [rows, setrows] = useState(null);
  const columns = [
    {
      id: "empRuc",
      label: "Ruc",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empRuc",
      align: "left",
      ocultar: false,
    },
    {
      id: "empNombre",
      label: "Nombre",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empNombre",
      align: "left",
      ocultar: false,
    },
    {
      id: "empRepresentante",
      label: "Representante",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empRepresentante",
      align: "left",
      ocultar: false,
    },
    {
      id: "empComercial",
      label: "Comercial",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empComercial",
      align: "left",
      ocultar: false,
    },
    {
      id: "empObligado",
      label: "Obligado",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empObligado",
      align: "center",
      ocultar: false,
    },
    {
      id: "empDireccion",
      label: "Direccion",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empDireccion",
      align: "left",
      ocultar: false,
    },
    {
      id: "empTelefono",
      label: "Telefono",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empTelefono",
      align: "center",
      ocultar: false,
    },
    {
      id: "empUser",
      label: "User",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "empUser",
      align: "center",
      ocultar: false,
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState({
    empDocumentos: {},
    empTerminosCarnet: "",
    empTipografia: "",
    empRuc: "",
    empNombre: "",
    empRepresentante: "",
    empComercial: "",
    empObligado: "",
    empDireccion: "",
    empTelefono: "",
    empTipo: "",
    empIva: "",
    empIce: "",
    empEstado: "ACTIVO",
    empLogo: "",
    empFirma: "",
    empClavef: "",
    empCodigo: "",
    empRegimen: "",
    empFechaCaducaFirma: "",
    correoEnv: "",
    correoResp: "",
    empCopiaEmail: "",
    empPaginaWeb: "",
    empFacebook: "",
    empInstagram: "",
    empColor: "",
    empUser: "",
    empFecReg: "",
    Todos: "",
    empCategoria: "START",
    empColorPrimario: "#2196f3",
    empColorSecundario: "#263238",
    colorDrawer: "#ffffff",
    empClaves: [],
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    try {
      const res = await clienteAxios.get(
        `/empresas/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}`
      );

      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR[campoExport] === item[campoExport]) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row[campoExport] !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row[campoExport];
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };

  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("empresa");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const [estadoRequest] = useState("");
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [campoExport] = useState("empId");
  const [input, setinput] = useState({
    id: campoExport,
    label: "ID",
    minWidth: 150,
    tipo: "string",
    propiedad: campoExport,
  });
  const [arrayExport, setarrayExport] = useState([]);

  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(true);
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());

  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setcargando(false);
  };

  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <SocketProvider>
      <Box>
        <DialogOpciones
          open={openDialogOpciones}
          setopen={setopenDialogOpciones}
          botones={[
            {
              tooltip: "Agregar",
              funcion: () => {
                setopen(true);
              },
              Icon: Add,
              color: "primary",
              id: 1,
              disabled: false,
            },
          ]}
          titulo={"Mas Opciones"}
        />
        <BarraTablas
          search={search}
          setsearch={setsearch}
          funcion={handleSearch}
          buscando={buscando}
          setbuscando={setbuscando}
          LabelBuscador="Buscar por todos los campos"
          funcionDefault={funcionDefault}
          ocultarMigasDePan={false}
          botones={[]}
        />
        <ModalB
          defaultFullBuscador={defaultFullBuscador}
          openMB={openMB}
          setopenMB={setopenMB}
          titulo={"Busqueda avanzada (Empresas)"}
          fullBuscador={fullBuscador}
          setfullBuscador={setfullBuscador}
          arrayProps={columns}
          handleChange={handleChange}
          funcionB={funcionB}
          buscando={buscando}
          funcionDefault={funcionDefault}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems=" stretch"
          width="100%"
        >
          <Box></Box>
        </Box>
        <Divider />
        <Tabla
          //PAGINACION Y BUSQUEDA
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setopen={setopen}
          search={search}
          input={input}
          orden={orden}
          setinput={setinput}
          setorden={setorden}
          cargando={cargando}
          setcargando={setcargando}
          fullBuscador={fullBuscador}
          //ESTADO
          rows={rows}
          columns={columns}
          pagination={pagination}
          getDataState={getDataState}
          obtenerState={obtenerState}
          addRowsState={addRowsState}
          remplazarRowsState={remplazarRowsState}
          changeObjetoActivoState={changeObjetoActivoState}
          seteditar={seteditar}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          socket={socket}
          campoExport={campoExport}
          tabla={tabla}
        />
        <Modal
          editar={editar}
          seteditar={seteditar}
          setopen={setopen}
          open={open}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
          socket={socket}
        />
      </Box>
    </SocketProvider>
  );
};

export default memo(Contabilidad);
