import { AddCircle, Delete } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useModalStyle } from "../../styles/styleModal";

const CamposAcumulablesCustom = ({
  error,
  propiedadTexto,
  item,
  setitem,
  tipoCampos,
  etiqueta,
  etiquetaCampo,
  disabled,
  disabledDelete,
  ocultarAdd,
}) => {
  const clases = useModalStyle();
  return (
    <>
      <FormControl className={clases.formAllW}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle2"
            color={error[propiedadTexto] ? "error" : "primary"}
          >
            {etiqueta}:
          </Typography>
          {ocultarAdd ? null : (
            <IconButton
              size="small"
              onClick={async () => {
                setitem({
                  ...item,
                  [propiedadTexto]: [
                    ...item[propiedadTexto],
                    {
                      nombre_dpt: "",
                      numero: "",
                      ubicacion: "",
                    },
                  ],
                });
              }}
            >
              <AddCircle fontSize="small" color="secondary" />
            </IconButton>
          )}
        </Box>
      </FormControl>
      {item[propiedadTexto].map((value, index) => {
        return (
          <React.Fragment key={index}>
            <Divider
              className={clases.formAllW}
              sx={{ display: "flex", alignItems: "center" }}
            >
              Registro #{index + 1}
              <IconButton
                disabled={disabledDelete}
                onClick={() => {
                  setitem({
                    ...item,
                    [propiedadTexto]: item[propiedadTexto].filter(
                      (item, indexItem) => indexItem !== index
                    ),
                  });
                }}
              >
                <Delete color="error" />
              </IconButton>
            </Divider>
            {/* */}
            <TextField
              className={clases.formD25}
              value={value.nombre_dpt}
              autoComplete="false"
              size="small"
              onChange={(e) => {
                // ajusta esta funcion para que cambie solo la propiedad 'nombre_dpt' del objeto actual
                setitem({
                  ...item,
                  [propiedadTexto]: item[propiedadTexto].map(
                    (item, indexItem) => {
                      if (indexItem === index)
                        return {
                          ...item,
                          nombre_dpt: e.target.value,
                        };

                      return item;
                    }
                  ),
                });
              }}
              label={`Nombre Casa / Depto`}
              InputProps={{
                endAdornment: <InputAdornment></InputAdornment>,
              }}
            />
            <TextField
              className={clases.formD25} 
              value={value.numero}
              autoComplete="false"
              size="small"
              onChange={(e) => {
                // ajusta esta funcion para que cambie solo la propiedad 'numero' del objeto actual
                setitem({
                  ...item,
                  [propiedadTexto]: item[propiedadTexto].map(
                    (item, indexItem) => {
                      if (indexItem === index)
                        return {
                          ...item,
                          numero: e.target.value,
                        };

                      return item;
                    }
                  ),
                });
              }}
              label={`Número`}
              InputProps={{
                endAdornment: <InputAdornment></InputAdornment>,
              }}
            />
            <TextField
              className={clases.formD25} 
              value={value.ubicacion}
              autoComplete="false"
              size="small"
              onChange={(e) => {
                // ajusta esta funcion para que cambie solo la propiedad 'ubicacion' del objeto actual
                setitem({
                  ...item,
                  [propiedadTexto]: item[propiedadTexto].map(
                    (item, indexItem) => {
                      if (indexItem === index)
                        return {
                          ...item,
                          ubicacion: e.target.value,
                        };

                      return item;
                    }
                  ),
                });
              }}
              label={`Ubicación`}
              InputProps={{
                endAdornment: <InputAdornment></InputAdornment>,
              }}
            />
            {/* faltan; numero
             */}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CamposAcumulablesCustom;
