/*eslint-disable*/

import React, { useState, memo } from "react";
// MODULOS
import { withRouter } from "react-router-dom";

// MATERIAL
import { Typography, Button, Box } from "@mui/material";
import Soporte from "./Soporte";
import logo from "../../assets/img/logo.png";
const Footer = (props) => {
  const [open, setopen] = useState(false);

  return (
    <>
      <Soporte setopen={setopen} open={open} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Button onClick={() => setopen(true)} variant="text" color="secondary">
          Soporte
          <img
            width={"55"}
            src={logo}
            alt="Logo Empresa"
            onClick={() => {
              props.history.push("/Contable/Dashboard");
            }}
          />
        </Button>
        <Typography
          variant="subtitle2"
          color="primary"
          component={"a"}
          href="https://neitor.com/"
          target="_blank"
        >
          &copy; {new Date().getFullYear()} || IMPULSADO POR NEITOR
        </Typography>
      </Box>
    </>
  );
};

export default memo(withRouter(Footer));
