import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";
import MuiImageSlider from "mui-image-slider";
// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";

import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";

// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  MenuItem,
  Dialog,
  Box,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Photo,
  Visibility,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";
import { DropzoneDialog } from "react-mui-dropzone";
import Cantidades from "../Cantidades/Cantidades";
import { uploadImagesHelper } from "../../../functions/uploads/subirImagenes";

const RowArma = (props) => {
  const classes = useRowStyles();
  const { bodegaData, ver, item, setitem, row, index, devolucion } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const [cargado, setcargado] = useState(false);
  useEffect(() => {
    if (cargado) {
      return funcSubmit();
    }
    setcargado(true);
    //eslint-disable-next-line
  }, [itemHijoEdit.cantidades]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);

  const [open, setOpen] = useState(false);
  const [vestimentaData, setvestimentaData] = useState([]);
  const [expandido, setexpandido] = useState(false);
  const PopperMy = function(props) {
    return <Popper {...props} placement="bottom-start" />;
  };

  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      disVestimenta: item.disVestimenta.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };

  const [editar, seteditar] = useState(false);

  const botonDisabled = () => {
    if (
      itemHijoEdit.nombre === "" ||
      itemHijoEdit.marca === "" ||
      itemHijoEdit.modelo === "" ||
      itemHijoEdit.talla === "" ||
      itemHijoEdit.color === "" ||
      itemHijoEdit.cantidades.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    setcargando(true);
    try {
      let resDocumentos = {
        data: {
          urls: itemHijoEdit.fotos,
        },
      };

      if (imagenes.length > 0) {
        resDocumentos = await uploadImagesHelper(imagenes, itemHijoEdit.fotos);
      }
      setitem({
        ...item,
        disVestimenta: item.disVestimenta.map((item, itemIndex) => {
          if (index === itemIndex) {
            return { ...itemHijoEdit, fotos: resDocumentos.data.urls };
          } else {
            return item;
          }
        }),
      });
      setimagenes([]);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  useEffect(() => {
    if (editar) {
      const getData = async () => {
        try {
          const res = await clienteAxios.get(
            `/inventario/filtroserie/0?tipo=${itemHijoEdit.tipo}&nombre=${itemHijoEdit.nombre}`
          );

          setvestimentaData(res.data.data);
        } catch (error) {
          return mostrarAlerta("No hay clientes", "error");
        }
      };
      getData();
    } // eslint-disable-next-line
  }, [editar]);

  return (
    <React.Fragment>
      <DropzoneDialog
        dialogProps={{ maxWidth: "lg" }}
        dialogTitle={
          <Typography variant="h5" color="primary">
            Subir archivos
          </Typography>
        }
        dropzoneText={
          <>
            <Typography variant="h4" color="primary">
              Arrastra una imagen o da click aqui.
            </Typography>
            {editar ? (
              <Typography variant="h5" color="error">
                Subir nuevas imagenes eliminará las anteriores.
              </Typography>
            ) : null}
          </>
        }
        acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Aceptar"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          setimagenes(files);
          setOpen(false);
        }}
        showPreviewsInDropzone={true}
        showPreviews={false}
        showAlerts={false}
        showFileNamesInPreview={false}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: ver || devolucion,
              Icon: DeleteForever,
              color: "error",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar",
              texto: "",
              funcion: () => {
                seteditar(true);
                setitemHijoEdit(row);
              },
              disabled: ver || devolucion,
              Icon: Create,
              color: "secondary",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitemHijoEdit(row);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: cargando || botonDisabled(),
              Icon: Check,
              color: "success",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver fotos",
              texto: itemHijoEdit.fotos.length,
              funcion: async () => {
                setOpen(true);
              },
              disabled: false,
              Icon: Photo,
              color: "primary",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: async () => {
                setexpandido((prev) => !prev);
              },
              disabled: false,
              Icon: expandido ? ExpandLess : ExpandMore,
              color: "primary",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <Dialog
          open={Openimagen}
          onClose={() => setOpenimagen(false)}
          aria-labelledby="draggable-dialog-title"
        >
          {row.fotos.length === 0 ? null : (
            <MuiImageSlider
              images={row.fotos.map((item) => item.url)}
              fitToImageHeight={true}
            />
          )}
        </Dialog>
        <TableCell size="small" align="center" className={claseCelda}>
          {row.fotos.length === 0 ? (
            "No hay imagenes"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagen(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={bodegaData.map((item) => {
                    return item.bodNombre + "/+/" + item.bodId;
                  })}
                  getOptionLabel={(option) => {
                    return option.split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={itemHijoEdit.bodega}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        bodega: options[0],
                        idBodega: options[1],
                      });
                      setvestimentaData([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                fullWidth
                size="small"
                id="tipo"
                value={itemHijoEdit.tipo}
                name="tipo"
                onChange={async (e) => {
                  setvestimentaData([]);
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    tipo: e.target.value,
                  });
                }}
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["VESTIMENTAS", "UTILITARIOS"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={vestimentaData.map((item) => {
                    return (
                      item.invNombre +
                      "/+/" +
                      item.invSerie +
                      "/+/" +
                      item.invStock +
                      "/+/" +
                      item.invId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option
                      .replace("/+/", "** ")
                      .replace("/+/", "** ")
                      .split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={
                    `${itemHijoEdit.nombre} / ${itemHijoEdit.serie}`.trim() ===
                    "/"
                      ? ""
                      : `${itemHijoEdit.nombre} / ${itemHijoEdit.serie}`
                  }
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      const itemF = vestimentaData.find(
                        (itemVestimenta) =>
                          itemVestimenta.invId === Number(options[3])
                      );
                      if (Number(itemF.invStock) === 0) {
                        return mostrarAlerta(
                          "Este producto ya se encuentra distribuido, no hay stock",
                          "error"
                        );
                      }
                      const hayDisponible = itemF.invTotalesStock.some(
                        (stock) => stock.cantidad > 0
                      );
                      if (hayDisponible) {
                        setexpandido(true);
                      }

                      setitemHijoEdit({
                        ...itemHijoEdit,
                        id: itemF.invId,
                        serie: itemF.invSerie,
                        valor: itemF.invPrecioUnitario,
                        nombre: itemF.invNombre,
                        talla: itemF.invTalla,
                        color: itemF.invColor,
                        marca: itemF.invMarca,
                        modelo: itemF.invModelo,
                        stock: itemF.invStock,
                        cantidades: itemF.invTotalesStock.map((stock) => ({
                          ...stock,
                          max: Number(stock.cantidad),
                          cantidad: Math.min(1, stock.cantidad),
                        })),
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      onChange={async (e) => {
                        const res = await clienteAxios.get(
                          `/inventario/filtroserie/0?tipo=${itemHijoEdit.tipo}&bodega=${itemHijoEdit.bodega}&nombre=${e.target.value}`
                        );
                        setvestimentaData(res.data.data);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {itemHijoEdit.talla}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {itemHijoEdit.color}
            </TableCell>

            <TableCell size="small" align="center" className={claseCelda}>
              {itemHijoEdit.valor}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.bodega}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.tipo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {`${row.nombre} / ${row.serie}`}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.talla}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.color}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.valor}
            </TableCell>
          </>
        )}
      </TableRow>
      <Cantidades
        ver={ver}
        devolucion={devolucion}
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoEdit}
        setitem={setitemHijoEdit}
      />
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
