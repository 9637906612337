import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  Email,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import ImagenCelda from "../../../../components/ImagenCelda";
import CeldaUser from "../../../../components/CeldaUser";
import CollapseCell from "../../../../components/CollapseCell";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // ESTADO,
    estadoRequest,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [celComprimida, setcelComprimida] = useState({});
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        vaca_user: usuario.usuario,
        vaca_empresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        vaca_periodo: rowHijo.vaca_periodo,
        perDocNumero: rowHijo.perDocNumero,
        // antTipo: rowHijo.antTipo,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          vaca_user: usuario.usuario + "/+/" + rowHijo[campoExport],
          vaca_empresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.vaca_user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: true,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado || estadoRequest === "PROCESADO",
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Enviar Email",
              texto: "",
              funcion: async () => {
                try {
                  const res = await clienteAxios.post(`rolpagos/enviarcorreo`, {
                    option: "individual",
                    rolpIdPersona: rowHijo.rolpIdPersona,
                    vaca_periodo: rowHijo.vaca_periodo,
                    perDocNumero: rowHijo.perDocNumero,
                  });
                  mostrarAlerta(res.data.data);
                } catch (error) {
                  mostrarAlerta("Hubo un error");
                }
              },
              disabled: false,
              Icon: Email,
              color: "primary",
              id: 1,
              ocultar: rowHijo.eliminado || estadoRequest !== "PROCESADO",
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: `PDF - ${rowHijo.vaca_empresa}`,
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/anticipo_individual?vaca_id=${rowHijo.vaca_id}&rucempresa=${rowHijo.vaca_empresa}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <ImagenCelda
            claseCelda={claseCelda}
            propiedad={rowHijo.vaca_comprobante}
          />
        
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.perDocNumero + " " + rowHijo.perNombres}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            ${rowHijo.vaca_valor}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.vaca_formaPago}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.vaca_periodo}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            sx={{
              fontWeight: "bold",
              color: (theme) =>
                rowHijo.vaca_estado === "APROBADA"
                  ? "green"
                  : theme.palette.error.main,
            }}
          >
            {rowHijo.vaca_estado}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={rowHijo}
            propiedad={"vaca_detalle"}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.vaca_fechaDesde === ""
              ? ""
              : new Date(rowHijo.vaca_fechaDesde).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.vaca_fechaHasta === ""
              ? ""
              : new Date(rowHijo.vaca_fechaHasta).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.vaca_fecreg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <CeldaUser user={rowHijo.vaca_user} claseCelda={claseCelda} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
