import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import Dropzone from "../../../components/Extra/Dropzone";
import Draggable from "react-draggable";
import {
  Cancel,
  CloudUpload,
  DeleteForever,
  Download,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import clienteAxios from "../../../config/axios";
import { createRef } from "react";
import ListPersonas from "../../components/ListPersonas";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import CampoDoc from "../../components/CampoDoc";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  // const { tienePermiso, alertaPermiso } = usePermiso("Examenes");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  // eslint-disable-next-line

  const [guardiasData, setguardiasData] = useState([]);

  const setitemsPersonas = (personas) => {
    return personas.map((itemGuardia) => {
      return {
        perFoto: itemGuardia.perFoto,
        perApellidos: itemGuardia.perApellidos,
        perNombres: itemGuardia.perNombres,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
        asistencia: false,
      };
    });
  };
  const [seccionActive, setseccionActive] = useState({
    DatosSeccion: true,
    TipoDocumentoSeccion: false,
    DatosArchivos: false,
  });
  const [seccionActivePersonas, setseccionActivePersonas] = useState({
    guardiasSeccion: true,
    supervisoresSeccion: false,
    administrativosSeccion: false,
  });
  const {
    guardiasSeccion,
    supervisoresSeccion,
    administrativosSeccion,
  } = seccionActivePersonas;
  const { DatosSeccion, TipoDocumentoSeccion, DatosArchivos } = seccionActive;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const [clientesData, setclientesData] = useState([]);
  const [supervisoresDataInput, setsupervisoresDataInput] = useState([]);
  const [encuestasData, setencuestasData] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const {
    // capaId: 1,
    // capaEncId,
    capaGuardias,
    capaSupervisores,
    capaAdministracion,
    capaDetalles,
    // capaIdCliente,
    capaNombreCliente,
    // capaIdCapacitador,
    capaNombreCapacitador,
    // capaFecUpd,
    capaTitulo,
    capaPrioridad,
    capaFecDesde,
    capaEstado,
    capaFecHasta,
    capaTipoCapacitador,
    capaDocumento,
    capaFotos,
    capaEncTitulo,
    capaTipoDoc,
  } = item;
 
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [supervisoresData, setsupervisoresData] = useState(null);
  const [administracionData, setadministracionData] = useState(null);
  useEffect(() => {
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };
    if (!administracionData) {
      getDataadministracionData();
    }
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=SUPERVISORES"
      );

      setsupervisoresData(
        setitemsPersonas(ressupervisoresData.data.data, true)
      );
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }
    if (open) {
      const getresguardiasData = async () => {
        try {
          // const itemF = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.capaDocumentoCliente}&estado=CLIENTES`
          // );
          const res = await clienteAxios.get(
            `/personas/listado/:codigo?docnumero=${ObjectoActivo.capaDocumentoCliente}`
          );

          setguardiasData(setitemsPersonas(res.data.data));
        } catch (error) {
          setguardiasData([]);
        }
      };

      if (editar) {
        getresguardiasData();
      }

      setitem(ObjectoActivo);
    }
    // eslint-disable-next-line
  }, [ObjectoActivo, open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      // capaId: 1,
      capaEncId: 0,
      capaEncTitulo: "",
      capaTipoDoc: "ENCUESTAS",
      capaDetalles: "",
      capaGuardias: [
        // {
        //   perId: "1333",
        //   perDocNumero: "1719915363",
        //   perNombres: "JORGE FRANCISCO",
        //   perApellidos: "CRIOLLO PARRALES",
        //   perFoto: "",
        //   asistencia: false,
        // },
      ],
      capaSupervisores: [
        // {
        //   perId: "1217",
        //   perDocNumero: "2300016462",
        //   perNombres: "RICHARD GERMAN",
        //   perApellidos: "TORRES ROBLEZ",
        //   perFoto: "",
        //   asistencia: false,
        // },
      ],
      capaAdministracion: [
        // {
        //   perId: "1326",
        //   perDocNumero: "1710656016",
        //   perNombres: "VALERIA CRISTINA",
        //   perApellidos: "YANEZ REYES",
        //   perFoto: "",
        //   asistencia: false,
        // },
      ],
      capaFecUpd: "",
      capaFecReg: "",
      capaIdCliente: "",
      capaNombreCliente: "",
      capaDocumentoCliente: "",
      capaTipoCapacitador: "SUPERVISOR",
      capaIdCapacitador: "",
      capaNombreCapacitador: "",
      capaTitulo: "",
      capaPrioridad: "MEDIA",
      capaFecDesde: FechaExacta,
      capaFecHasta: FechaExacta,
      capaEstado: "ACTIVA",
      capaDocumento: "",
      capaFotos: [],
      capaUser: "",
      capaEmpresa: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setguardiasData([]);
    // setdocumentoconDocumento("");
    setimagenes([]);
    setmodificoImagenes(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const validacion = () => {
    if (
      (capaGuardias.length === 0 &&
        capaSupervisores.length === 0 &&
        capaAdministracion.length === 0) ||
      capaDetalles.trim() === "" ||
      (capaNombreCliente.trim() === "" &&
        capaSupervisores.length === 0 &&
        capaAdministracion.length === 0) ||
      capaTipoCapacitador.trim() === "" ||
      capaNombreCapacitador.trim() === "" ||
      capaTitulo.trim() === "" ||
      capaPrioridad.trim() === "" ||
      capaFecDesde.trim() === "" ||
      capaFecHasta.trim() === "" ||
      capaEstado.trim() === ""
    ) {
      seterror({
        capaGuardias:
          capaGuardias.length === 0 &&
          capaSupervisores.length === 0 &&
          capaAdministracion.length === 0,
        capaSupervisores:
          capaGuardias.length === 0 &&
          capaSupervisores.length === 0 &&
          capaAdministracion.length === 0,
        capaAdministracion:
          capaGuardias.length === 0 &&
          capaSupervisores.length === 0 &&
          capaAdministracion.length === 0,
        capaDetalles: capaDetalles.trim() === "",
        capaNombreCliente:
          capaNombreCliente.trim() === "" &&
          capaSupervisores.length === 0 &&
          capaAdministracion.length === 0,
        capaTipoCapacitador: capaTipoCapacitador.trim() === "",
        capaNombreCapacitador: capaNombreCapacitador.trim() === "",
        capaTitulo: capaTitulo.trim() === "",
        capaPrioridad: capaPrioridad.trim() === "",
        capaFecDesde: capaFecDesde.trim() === "",
        capaFecHasta: capaFecHasta.trim() === "",
        capaEstado: capaEstado.trim() === "",
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, capaFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          capaFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "capacitacion",
          capaUser: usuario.usuario,
          capaEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo unxd error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      // let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }

      const resDocumentos = await subirImagenes(imagenes, capaFotos);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          capaFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "capacitacion",
          capaUser: usuario.usuario,
          capaEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  if (!open) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Capacitacion"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      DatosSeccion ? "contained" : false ? "outlined" : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={false ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosSeccion: true })}
                  >
                    Datos
                  </Button>
                  <Button
                    variant={
                      TipoDocumentoSeccion
                        ? "contained"
                        : false
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={false ? "error" : "secondary"}
                    onClick={() =>
                      setseccionActive({ TipoDocumentoSeccion: true })
                    }
                  >
                    Encuesta / Evaluacion
                  </Button>
                  <Button
                    variant={
                      DatosArchivos ? "contained" : false ? "outlined" : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={false ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosArchivos: true })}
                  >
                    Archivos
                  </Button>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                mt={1}
                maxHeight={"60vh"}
                overflow="scroll"
                sx={{ overflowX: "hidden" }}
              >
                {DatosSeccion ? (
                  <>
                    {editar ? (
                      <TextField
                        className={clases.formD}
                        size="small"
                        label="Estado"
                        value={capaEstado}
                        name="capaEstado"
                        error={error.capaEstado}
                        onChange={(e) => {
                          setsupervisoresDataInput([]);
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem disabled value="">
                          Seleccione.
                        </MenuItem>
                        {["ACTIVA", "FINALIZADA", "ANULADA"].map((item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        })}
                      </TextField>
                    ) : null}
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Titulo"
                      value={capaTitulo}
                      name="capaTitulo"
                      error={error.capaTitulo}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* CLIENTES */}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={capaNombreCliente}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const res = await clienteAxios.get(
                              `/personas/listado/:codigo?docnumero=${options[1]}`
                            );

                            setguardiasData(setitemsPersonas(res.data.data));

                            try {
                              setitem({
                                ...item,
                                capaIdCliente: options[2],
                                capaNombreCliente: options[0],
                                capaDocumentoCliente: options[1],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Selección Cliente"
                            error={error.capaNombreCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>{" "}
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Tipo Capacitador"
                      value={capaTipoCapacitador}
                      name="capaTipoCapacitador"
                      error={error.capaTipoCapacitador}
                      onChange={(e) => {
                        setsupervisoresDataInput([]);
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                          capaIdCapacitador: "",
                          capaNombreCapacitador: "",
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["SUPERVISOR", "ADMINISTRACION"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>{" "}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={supervisoresDataInput.map((item) => {
                          return (
                            item.perApellidos +
                            " " +
                            item.perNombres +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={capaNombreCapacitador}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              setitem({
                                ...item,
                                capaIdCapacitador: options[1],
                                capaNombreCapacitador: options[0],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label={`Selección ${capaTipoCapacitador}`}
                            error={error.capaNombreCapacitador}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/personas/filtro/0?search&estado=${
                                    capaTipoCapacitador === "SUPERVISOR"
                                      ? "SUPERVISORES"
                                      : "ADMINISTRACION"
                                  }`
                                );

                                setsupervisoresDataInput(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>{" "}
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Prioridad"
                      value={capaPrioridad}
                      name="capaPrioridad"
                      error={error.capaPrioridad}
                      onChange={(e) => {
                        setsupervisoresDataInput([]);
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                          capaIdCapacitador: "",
                          capaNombreCapacitador: "",
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["BAJA", "MEDIA", "ALTA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>{" "}
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      size="small"
                      label="F. Desde"
                      value={capaFecDesde}
                      name="capaFecDesde"
                      error={error.capaFecDesde}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      size="small"
                      label="F. Hasta"
                      value={capaFecHasta}
                      name="capaFecHasta"
                      error={error.capaFecHasta}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Detalles u Observación"
                      value={capaDetalles}
                      name="capaDetalles"
                      error={error.capaDetalles}
                      onChange={(e) => handleChange(e)}
                    />
                    <Box className={clases.formAllW}>
                      <Button
                        variant={
                          guardiasSeccion
                            ? "contained"
                            : false
                            ? "outlined"
                            : null
                        }
                        disableElevation
                        style={{ padding: "8px" }}
                        color={false ? "error" : "secondary"}
                        onClick={() =>
                          setseccionActivePersonas({ guardiasSeccion: true })
                        }
                      >
                        guardias
                      </Button>
                      <Button
                        variant={
                          supervisoresSeccion
                            ? "contained"
                            : false
                            ? "outlined"
                            : null
                        }
                        disableElevation
                        style={{ padding: "8px" }}
                        color={false ? "error" : "secondary"}
                        onClick={() =>
                          setseccionActivePersonas({
                            supervisoresSeccion: true,
                          })
                        }
                      >
                        supervisores
                      </Button>
                      <Button
                        variant={
                          administrativosSeccion
                            ? "contained"
                            : false
                            ? "outlined"
                            : null
                        }
                        disableElevation
                        style={{ padding: "8px" }}
                        color={false ? "error" : "secondary"}
                        onClick={() =>
                          setseccionActivePersonas({
                            administrativosSeccion: true,
                          })
                        }
                      >
                        administrativos
                      </Button>
                    </Box>
                    {guardiasSeccion ? (
                      <ListPersonas
                        error={error}
                        item={item}
                        setitem={setitem}
                        data={guardiasData}
                        propiedad={capaGuardias}
                        propiedadString={"capaGuardias"}
                      />
                    ) : null}
                    {supervisoresSeccion ? (
                      <ListPersonas
                        error={error}
                        item={item}
                        setitem={setitem}
                        data={supervisoresData}
                        propiedad={capaSupervisores}
                        propiedadString={"capaSupervisores"}
                      />
                    ) : null}
                    {administrativosSeccion ? (
                      <ListPersonas
                        error={error}
                        item={item}
                        setitem={setitem}
                        data={administracionData}
                        propiedad={capaAdministracion}
                        propiedadString={"capaAdministracion"}
                      />
                    ) : null}
                  </>
                ) : null}
                {TipoDocumentoSeccion ? (
                  <>
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Tipo"
                      value={capaTipoDoc}
                      name="capaTipoDoc"
                      error={error.capaTipoDoc}
                      onChange={(e) => {
                        setencuestasData([]);
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                          capaEncTitulo: "",
                          capaEncId: 0,
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["ENCUESTAS", "EVALUACIONES"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={encuestasData.map((item) => {
                          return item.docTitulo + "/+/" + item.docId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={capaEncTitulo}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              setitem({
                                ...item,
                                capaEncId: options[1],
                                capaEncTitulo: options[0],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label={`Selección ${capaTipoDoc}`}
                            error={error.capaEncTitulo}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/evaluaciones/listado/0?option=${capaTipoDoc}&search=${e.target.value}`
                                );

                                setencuestasData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                ) : null}
                {DatosArchivos ? (
                  <>
                    <> 
                    <CampoDoc
                      setcargando={setcargando}
                      label="Documento"
                      propiedad={"capaDocumento"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                   
                    </>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={capaFotos}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                ></Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        // if (!tienePermiso("editar")) {
                        // return alertaPermiso("editar");
                        // }
                        handleEditar();
                      } else {
                        // if (!tienePermiso("agregar")) {
                        //   return alertaPermiso("agregar");
                        // }
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
