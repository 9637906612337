import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete } from "@mui/material";
import Dropzone from "../../Extra/Dropzone";
import InformesContext from "../../../Context/Informes/InformesContext";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  FormControl,
  FormControlLabel,
  Checkbox,
  Collapse,
  ListItem,
  ListItemText,
  Avatar,
  List,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  Cancel,
  ExpandLess,
  ExpandMore,
  Backspace,
  CloudUpload,
  DeleteForever,
  Download,
} from "@mui/icons-material";
import useMultimediasUpload from "../../../views/hooks/useMultimediasUpload";
import CampoDoc from "../../../views/components/CampoDoc";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "60%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const { ObjectoActivo, change } = useContext(InformesContext);

  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { socket, usuario } = useContext(AuthContext);
  //props
  const { open, setopen, editar, seteditar } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [filtrando, setfiltrando] = useState(false);
  const [filtro, setfiltro] = useState("");
  const [clientesDataDefault, setclientesDataDefault] = useState([]);
  const containerRef = React.useRef(null);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  //documento
 
 
  // eslint-disable-next-line
  const {
    // infIdDirigido,
    infNomDirigido,
    infAsunto,
    // infTipoNovedad,
    infFechaSuceso,
    infLugar,
    infPerjudicado,
    // infPorque,
    infSucedido,
    infGuardias,
    infFotos,
    infConclusiones,
    infRecomendaciones,
    infDocumento,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    infIdDirigido: false,
    infNomDirigido: false,
    infAsunto: false,
    infTipoNovedad: false,
    infFechaSuceso: false,
    infLugar: false,
    infPerjudicado: false,
    infPorque: false,
    infSucedido: false,
    infGuardias: false,
    infFotos: false,
    infVideo: false,
  });

  useEffect(() => {
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    change({
      infCorreo: [],
      infIdDirigido: "", //cliente
      infNomDirigido: "", //cliente
      infDocNumDirigido: "",
      infAsunto: "", //input
      infTipoNovedad: "", //input
      infFechaSuceso: "", //fecha hora
      infLugar: "", //input
      infPerjudicado: "", //input
      infPorque: "", // input
      infSucedido: "",
      infGuardias: [
        // {
        //   docnumero: "0850492372",
        //   nombres: "JIMMY MANUEL  MINOTA GUERRERO",
        //   asignado: true,
        //   id: 1045,
        //   foto: "",
        // },
      ],
      infFotos: [], //fotos
      infVideo: [],
      infUser: "",
      infEmpresa: "",
      infFecReg: "",
      infConclusiones: "",
      infRecomendaciones: "",
      infPara: "CLIENTES",
      infDocumento: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      infIdDirigido: false,
      infNomDirigido: false,
      infAsunto: false,
      infTipoNovedad: false,
      infFechaSuceso: false,
      infLugar: false,
      infPerjudicado: false,
      infPorque: false,
      infSucedido: false,
      infGuardias: false,
      infFotos: false,
      infVideo: false,
    });
    setseccionActive({
      DatosGenerales: true,
      DatosArchivos: false,
    });

    setfiltrando(false);
    setfiltro("");
    setclientesDataDefault([]);
    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (
      infNomDirigido.trim() === "" ||
      infAsunto.trim() === "" ||
      infFechaSuceso.trim() === "" ||
      infLugar.trim() === "" ||
      infPerjudicado.trim() === ""
    ) {
      seterror({
        infNomDirigido: infNomDirigido.trim() === "" ? true : false,
        infAsunto: infAsunto.trim() === "" ? true : false,

        infFechaSuceso: infFechaSuceso.trim() === "" ? true : false,
        infLugar: infLugar.trim() === "" ? true : false,
        infPerjudicado: infPerjudicado.trim() === "" ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    DatosArchivos: false,
  });
  const [openCollapse, setopenCollapse] = useState(false);
  // eslint-disable-next-line
  const { DatosGenerales, DatosArchivos } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (submit()) {
      return mostrarAlerta("Los campos son obligatorios", "error");
    }
    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, infFotos);

      socket.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          infFotos: resDocumentos,
          infGuardias: infGuardias.filter((itemFilt) => itemFilt.asignado),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          infUser: usuario.usuario,
          infEmpresa: usuario.rucempresa,
          infRol: usuario.rol,
          tabla: "informe",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (submit()) {
      return mostrarAlerta("Los campos son obligatorios", "error");
    }
    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, infFotos);

      socket.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          infFotos: resDocumentos,
          infGuardias: infGuardias.filter((itemFilt) => itemFilt.asignado),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          infEmpresa: usuario.rucempresa,
          infRol: usuario.rol,
          tabla: "informe",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Informe.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={DatosGenerales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button>

                  <Button
                    variant={DatosArchivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosArchivos: true })}
                  >
                    Datos Archivos
                  </Button>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                {" "}
                <Fade
                  direction="up"
                  in={DatosGenerales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosGenerales ? (
                      <>
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            disableClearable={true}
                            options={clientesData.map((item) => {
                              return (
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliDocNumero +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={infNomDirigido}
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                const res = await clienteAxios.get(
                                  `/personas/listado/:codigo?docnumero=${options[1]}`
                                );

                                const itemF = clientesData.find(
                                  (itemCli) =>
                                    itemCli.cliId === Number(options[2])
                                );

                                const arrayMap = res.data.data.map((item) => {
                                  return {
                                    perPuestoServicio: item.perPuestoServicio,
                                    docnumero: item.perDocNumero, // propiedad => perDocNumero
                                    nombres: `${item.perNombres}  ${item.perApellidos}`, // propiedad  => perNombres perApellidos
                                    asignado: true,
                                    id: item.perId,
                                    foto: item.perFoto,
                                    correo: item.perEmail,
                                  };
                                });
                                setclientesDataDefault(arrayMap);
                                setitem({
                                  ...item,
                                  infCorreo: itemF.perEmail,
                                  infNomDirigido: options[0],
                                  infDocNumDirigido: options[1],
                                  infIdDirigido: options[2],
                                  infGuardias: arrayMap,
                                });
                              } else {
                                setitem({
                                  ...item,
                                  infCorreo: [],
                                  infNomDirigido: "",
                                  infDocNumDirigido: "",
                                  infIdDirigido: "",
                                  infGuardias: [],
                                });
                                setclientesData([]);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                fullWidth
                                error={error.infNomDirigido}
                                label="Cliente"
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    setitem({
                                      ...item,
                                    });
                                    setclientesData([]);

                                    return mostrarAlerta(
                                      "No hay clientes",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="infAsunto"
                          label="Asunto"
                          value={infAsunto}
                          name="infAsunto"
                          error={error.infAsunto}
                          onChange={(e) => handleChange(e)}
                        />

                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="infLugar"
                          label="Lugar"
                          value={infLugar}
                          name="infLugar"
                          error={error.infLugar}
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="infPerjudicado"
                          label="Implicado"
                          value={infPerjudicado}
                          name="infPerjudicado"
                          error={error.infPerjudicado}
                          onChange={(e) => handleChange(e)}
                        />

                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="infSucedido"
                          label="Detalle de la novedad"
                          value={infSucedido}
                          name="infSucedido"
                          error={error.infSucedido}
                          onChange={(e) => handleChange(e)}
                          multiline
                          rows={3}
                          variant="outlined"
                        />

                        {usuario.rol.some((rolI) => rolI !== "GUARDIA") ? (
                          <>
                            {" "}
                            <TextField
                              inputProps={{ className: clases.inputPadding }}
                              className={clases.formAllW}
                              size="small"
                              id="infConclusiones"
                              label="Conclusiones"
                              value={infConclusiones}
                              name="infConclusiones"
                              error={error.infConclusiones}
                              onChange={(e) => handleChange(e)}
                              multiline
                              rows={3}
                              variant="outlined"
                            />
                            <TextField
                              inputProps={{ className: clases.inputPadding }}
                              className={clases.formAllW}
                              size="small"
                              id="infRecomendaciones"
                              label="Recomendaciones"
                              value={infRecomendaciones}
                              name="infRecomendaciones"
                              error={error.infRecomendaciones}
                              onChange={(e) => handleChange(e)}
                              multiline
                              rows={3}
                              variant="outlined"
                            />{" "}
                          </>
                        ) : null}
                        <TextField
                          type={"datetime-local"}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="infFechaSuceso"
                          label="Fecha"
                          value={infFechaSuceso}
                          name="infFechaSuceso"
                          error={error.infFechaSuceso}
                          onChange={(e) => handleChange(e)}
                        />

                        <Box className={clases.formAllW}>
                          <ListItem>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    !infGuardias.some(
                                      (item) => item.asignado === false
                                    )
                                  }
                                  onChange={(e) => {
                                    setitem({
                                      ...item,
                                      infGuardias: infGuardias.map(
                                        (consigna) => {
                                          if (
                                            !infGuardias.some(
                                              (item) => item.asignado === false
                                            )
                                          ) {
                                            return {
                                              ...consigna,
                                              asignado: false,
                                            };
                                          } else {
                                            return {
                                              ...consigna,
                                              asignado: true,
                                            };
                                          }
                                        }
                                      ),
                                    });
                                  }}
                                />
                              }
                            />

                            <ListItemText
                              primary="Guardias"
                              style={{ marginRight: "1rem" }}
                            />
                            <Box
                              display={"flex"}
                              width="100%"
                              alignItems="center"
                              height={"100%"}
                            >
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                size="small"
                                label="Filtrar"
                                value={filtro}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment>
                                      {filtrando ? (
                                        <IconButton
                                          aria-label=""
                                          onClick={() => {
                                            setfiltrando(false);
                                            setfiltro("");
                                            setitem({
                                              ...item,
                                              infGuardias: clientesDataDefault,
                                            });
                                          }}
                                        >
                                          <Backspace color="error" />
                                        </IconButton>
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setfiltrando(false);
                                    setfiltro("");
                                    return setitem({
                                      ...item,
                                      infGuardias: clientesDataDefault,
                                    });
                                  }
                                  setfiltrando(true);
                                  setfiltro(e.target.value);
                                  const res = clientesDataDefault.filter(
                                    (itemCliente) =>
                                      itemCliente.nombres
                                        .toLowerCase()
                                        .includes(
                                          e.target.value.toLowerCase()
                                        ) ||
                                      itemCliente.docnumero
                                        .toLowerCase()
                                        .includes(
                                          e.target.value.toLowerCase()
                                        ) ||
                                      itemCliente.perPuestoServicio
                                        .map((itemPuesto) =>
                                          `  
                                            Puesto: ${itemPuesto.puesto}  
                                            Ubicación: ${itemPuesto.ubicacion} 
                                            `.toLowerCase()
                                        )
                                        .join(", ")
                                        .includes(e.target.value.toLowerCase())
                                  );
                                  setitem({ ...item, infGuardias: res });
                                }}
                              />{" "}
                            </Box>
                            <IconButton
                              aria-label=""
                              onClick={() => setopenCollapse(!openCollapse)}
                            >
                              {openCollapse ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </ListItem>
                          <Divider />
                          <Collapse
                            in={openCollapse}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List dense component="div" disablePadding>
                              {[...infGuardias].map((consignaList, index) => {
                                return (
                                  <ListItem style={{ paddingLeft: "1rem" }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={consignaList.asignado}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              infGuardias: infGuardias.map(
                                                (consigna) => {
                                                  if (
                                                    consigna.id ===
                                                    consignaList.id
                                                  ) {
                                                    return {
                                                      ...consignaList,
                                                      asignado: !consignaList.asignado,
                                                    };
                                                  } else {
                                                    return {
                                                      ...consigna,
                                                    };
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                      }
                                    />

                                    <ListItemText
                                      primary={
                                        consignaList.nombres +
                                        " " +
                                        consignaList.perPuestoServicio
                                          .map(
                                            (itemPuesto) => `  
                                          Puesto: ${itemPuesto.puesto}  
                                          Ubicación: ${itemPuesto.ubicacion} 
                                          `
                                          )
                                          .join(", ")
                                      }
                                    />
                                    <Avatar
                                      alt={`Avatar: ${consignaList.nombres}`}
                                      src={consignaList.foto}
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Fade>
                {/* DATOS ARCHIVOS */}
                <Fade
                  direction="up"
                  in={DatosArchivos}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosArchivos ? (
                      <>
                        <>
                          <CampoDoc
                            setcargando={setcargando}
                            label="Documento"
                            propiedad={"infDocumento"}
                            item={item}
                            setitem={setitem}
                            celda={false}
                          />
                        </>
                        <Dropzone
                          imagenes={imagenes}
                          setimagenes={(e) => {
                            setmodificoImagenes(true);
                            setimagenes(e);
                          }}
                          fotosItem={infFotos}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
