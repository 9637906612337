import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
  
import AuthContext from "../../../../Context/Auth/AuthContext";
import { SocketContext } from "../../../../Context/WhatsappSocket/SocketContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { useModalStyle } from "../../../styles/styleModal";

export const WhatsappConexion = ({ setseccionActive }) => {
  const { empresaSocket } = useContext(SocketContext);
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [qr, setqr] = useState(null);
  const [autenticadoWhatsapp, setautenticadoWhatsapp] = useState(false);
  const clases = useModalStyle();
  useEffect(() => {
    empresaSocket.emit("client:verificarSession", {
      rucempresa: usuario.rucempresa,
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    empresaSocket.on("server:noExisteArchivoSession", (data) => {
      mostrarAlerta("No hay conexión con whatsapp", "warning");
      if (data.msg) {
        empresaSocket.emit("client:newQR", { rucempresa: usuario.rucempresa });
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    empresaSocket.on("server:generaqr", (data) => {
      setqr(data.url);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    empresaSocket.on("server:authenticationSuccess", (data) => {
      mostrarAlerta("Se generó una conexión con whatsapp", "success");
      setautenticadoWhatsapp(data.msg);
      if (data.msg) {
        empresaSocket.off("server:generaqr");
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Typography
        onClick={async () => {
          await Axios.post("https://whatsappbot.neitor.com/api/send_messages", {
            rucempresa: usuario.rucempresa,
            message: `📛 Mensaje desde empresa: ${usuario.rucempresa}`,
            number: "939182104",
          });
        }}
        variant="subtitle1"
        color="primary"
        className={clases.formD}
      >
        Conectado:{" "}
        <Typography
          component="span"
          variant="subtitle1"
          color={autenticadoWhatsapp ? "green" : "error"}
        >
          {autenticadoWhatsapp ? "SI" : "NO"}
        </Typography>
      </Typography>
      {autenticadoWhatsapp ? (
        <Button
          color="error"
          variant="contained"
          onClick={async () => {
            const res = await clienteAxios.put(
              "https://whatsappbot.neitor.com/api/delete_session",
              {
                codigoEmpresa: usuario.rucempresa,
              }
            );

            mostrarAlerta(res.data.msg, "success");
            setseccionActive("cargando");
          }}
        >
          Eliminar Conexión
        </Button>
      ) : (
        <Box
          className={clases.formAllW}
          display={"flex"}
          justifyContent={"center"}
        >
          {qr && <img src={qr} alt="QR conexión" />}
        </Box>
      )}
    </>
  );
};
